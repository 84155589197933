import { useEffect, useState } from 'react';
import './styles.css';

const timeOut = 2;

const MessageBox = ({ message, type, duration = timeOut * 1000, onClear }) => {
  const [visible, setVisible] = useState(!!message);

  useEffect(() => {
    if (message && visible) {
      const timer = setTimeout(() => {
        setVisible(false);
        if (onClear) onClear();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [message, visible, duration, onClear]);

  if (!visible) return null;

  return <div className={`message-box ${type}-message`}>{message}</div>;
};

export default MessageBox;
