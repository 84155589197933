import '../resources/css/general.css';
import './styles.css';

import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import PayPeriodPublicRecord from '../../components/PayPeriodPublicRecord';

function PayCalendarPage() {
  const [payPeriods, setPayPeriods] = useState([]);

  useEffect(() => {
    async function fetchPayPeriods() {
      try {
        const response = await axios.get('/api/payPeriod');
        setPayPeriods(response.data);
      } catch (error) {
        console.log('Error fetching pay periods ' + error);
      }
    }

    fetchPayPeriods();
  }, []);

  return (
    <div>
      <Header />
      <div className='payPeriod-container pc-public'>
        <h2>Pay Calendar</h2>
        <div>
          <span className='from-to column-header'>
            <span>Performance Period</span>
          </span>
          <span className='due-date column-header'>
            <span>Due Date</span>
          </span>
          <span className='pay-date column-header'>
            <span>Pay Date</span>
          </span>
        </div>
        <div className='item-list'>
          {payPeriods.map((payPeriod) => (
            <PayPeriodPublicRecord
              key={payPeriod.id}
              payPeriod={payPeriod}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PayCalendarPage;
