import React, { useState, useEffect, useMemo } from "react";
import {
  formatShortDate,
  generateDateRange,
  getWeekdays,
  getHolidays,
  isWeekend,
  isHoliday,
  subtractTimes,
} from "../../utils/DateUtils";
import "./styles.css";
import TimeEditModal from "../TimeEditModal";
import {
  calculateTotalHours,
  calculateRegularHours,
  calculateOvertime,
  calculateWorkInHoliday,
  calculateSpecialHours,
  calculateLunchHours,
  calculateColumnSelfBreakHours,
  calculateSelfBreakHours,
  getTotalSelfBreakHoliday,
  getTotalSelfBreakRegular,
  getTotalHoursAtIndexK,
} from "./timesheetCalculations";
import { ERROR } from "../../constants/messageTypes";
import { ADMIN, MANAGER, SUPER_MANAGER } from "../../constants/roles";
import BreakEditModal from "../BreakEditModal";
import Dialog from "../Dialog";
import { CONFIRM, WARNING } from "../Dialog/dialogTypes";

const TimesheetTable = (props) => {
  const { fromDate, toDate, userId, isCompleted, approvedAt, processedAt, data } = props.timesheet;
  const [timesheet, setTimesheet] = useState(props.timesheet);
  const [holidays, setHolidays] = useState([]);
  const [holidayNames, setHolidayNames] = useState([]);
  const [dateHeaders, setDateHeaders] = useState([]);
  const [weekDayHeaders, setWeekDayHeaders] = useState([]);
  const [editModal, setEditModal] = useState({
    visible: false,
    rowIndex: null,
    columnIndex: null,
  });
  const [breakModal, setBreakModal] = useState({
    visible: false,
    columnIndex: null,
  });
  const [dialogModal, setDialogModal] = useState({
    visible: false,
    type: null,
    title: null,
    content: null,
    onConfirm: null,
    onCancel: null,
  });

  useEffect(() => {
    setTimesheet(props.timesheet);
  }, [props.timesheet, props.timesheetUser]);

  useEffect(() => {
    const _dateHeaders = generateDateRange(fromDate, toDate);
    setDateHeaders(_dateHeaders);
    const _weekDayHeaders = getWeekdays(_dateHeaders);
    setWeekDayHeaders(_weekDayHeaders);
  }, [fromDate, toDate]);

  useEffect(() => {
    let isMounted = true;

    const fetchHolidays = async () => {
      try {
        const yearsToFetchHolidaysFor = [2022, 2023];
        const fetchedHolidays = await getHolidays(yearsToFetchHolidaysFor);

        if (isMounted) {
          setHolidays(fetchedHolidays.holidayDates);
          setHolidayNames(fetchedHolidays.holidayNames);
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
      }
    };

    fetchHolidays();

    return () => {
      isMounted = false;
    };
  }, []);

  const weekendIndices = useMemo(() => {
    try {
      return dateHeaders
        .map((date, index) => (isWeekend(date) ? index : -1))
        .filter((index) => index !== -1);
    } catch (error) {
      console.error("Error checking weekend dates:", error);
      return [];
    }
  }, [dateHeaders]);

  const holidayIndices = useMemo(() => {
    try {
      return dateHeaders
        .map((date, index) => (isHoliday(date, holidays) ? index : -1))
        .filter((index) => index !== -1);
    } catch (error) {
      console.error("Error checking holiday dates:", error);
      return [];
    }
  }, [dateHeaders, holidays]);

  const holidayNameIndices = useMemo(() => {
    const getHolidayIndices = (dates, holidaysList) => {
      try {
        return dates
          .map((date, index) => (isHoliday(date, holidaysList) ? index : -1))
          .filter((index) => index !== -1);
      } catch (error) {
        console.error("Error checking holiday dates:", error);
        return [];
      }
    };

    return getHolidayIndices(dateHeaders, holidays);
  }, [dateHeaders, holidays]);

  const totalHours = useMemo(() => {
    if (!timesheet.data) return null;

    return calculateTotalHours(timesheet.data);
  }, [timesheet.data]);

  const lunchHours = useMemo(() => {
    if (!timesheet.data) return null;

    return calculateLunchHours(timesheet.data, timesheet.lunchBreak);
  }, [timesheet.data, timesheet.lunchBreak]);

  const regularHours = useMemo(() => {
    if (!timesheet.data || !weekendIndices || !holidayIndices) return null;

    return calculateRegularHours(timesheet.data, weekendIndices, holidayIndices);
  }, [timesheet.data, weekendIndices, holidayIndices]);

  const overtime = useMemo(() => {
    if (!timesheet.data || !weekendIndices || !holidayIndices) return null;

    return calculateOvertime(timesheet.data, weekendIndices, holidayIndices);
  }, [timesheet.data, weekendIndices, holidayIndices]);

  const workInHoliday = useMemo(() => {
    if (!timesheet.data || !holidayIndices) return null;

    return calculateWorkInHoliday(timesheet.data, holidayIndices);
  }, [timesheet.data, holidayIndices]);

  const specialHours = useMemo(() => {
    if (!timesheet.data) return null;

    return calculateSpecialHours(timesheet.data);
  }, [timesheet.data]);

  const showDialog = (type, title, content) => {
    return new Promise((resolve, reject) => {
      setDialogModal({
        visible: true,
        type,
        title,
        content,
        onConfirm: () => resolve("confirmed"),
        onCancel: () => reject("cancelled"),
      });
    });
  };

  const handleDeleteRow = (rowIndex) => {
    props.onDeleteRow(rowIndex);
  };

  const handleOpenModal = async (rowIndex, columnIndex) => {
    if (isCompleted) {
      props.onNotifyError(
        ERROR,
        "This timesheet is already passed due or locked. Cannot make changes."
      );
      return;
    }
    if (holidayIndices.includes(columnIndex)) {
      try {
        const result = await showDialog(
          CONFIRM,
          "Please Confirm!",
          "You are trying to modify hours in a holiday."
        );
        setEditModal({ visible: true, rowIndex, columnIndex });
      } catch (error) {
        // cancel
      } finally {
        setDialogModal((prev) => ({ ...prev, visible: false })); // Close the dialog
      }
    } else {
      setEditModal({ visible: true, rowIndex, columnIndex });
    }
  };

  const handleOpenBreakModal = (columnIndex) => {
    if (isCompleted) {
      props.onNotifyError(
        ERROR,
        "This timesheet is already passed due or locked. Cannot make changes."
      );
      return;
    }
    if (getTotalHoursAtIndexK(timesheet.data, columnIndex) === 0) {
      props.onNotifyError(ERROR, "Cannot add self break to a column with 0 hours.");
      return;
    }
    setBreakModal({ visible: true, breakData: timesheet.selfBreaks, columnIndex: columnIndex });
  };

  const handleCloseModal = () => {
    setEditModal({ visible: false, rowIndex: null, columnIndex: null });
  };

  const handleChangeInterval = (newInterval) => {
    const { rowIndex, columnIndex } = editModal;

    const newData = timesheet.data.map((row, i) =>
      i === rowIndex
        ? {
            ...row,
            data: row.data.map((col, j) => (j === columnIndex ? newInterval : col)),
          }
        : row
    );

    const updatedTimesheet = { ...timesheet, data: newData };
    setTimesheet(updatedTimesheet);
    handleCloseModal();

    // Notify parent about the updated timesheet
    props.onTimesheetUpdate && props.onTimesheetUpdate(updatedTimesheet);
  };

  const handleChangeBreak = (newBreaks) => {
    const updatedTimesheet = { ...timesheet, selfBreaks: newBreaks };
    setTimesheet(updatedTimesheet);

    // Notify parent about the updated timesheet
    props.onTimesheetUpdate && props.onTimesheetUpdate(updatedTimesheet);
  };

  const handleCloseBreakModal = () => {
    setBreakModal({ visible: false, columnIndex: null });
  };

  // Regular Hours -----------------------------------------------------
  const getRegularHours = () => {
    return regularHours?.reduce((a, b) => a + b, 0) ?? 0;
  };

  const getRegularLunchHours = () => {
    const regularLunchHours = lunchHours.filter(
      (_, index) => !holidayIndices.includes(index) && !weekendIndices.includes(index)
    );
    return -1 * regularLunchHours?.reduce((a, b) => a + b, 0) ?? 0;
  };

  const getNetRegularHours = () => {
    return (
      getRegularHours() -
      getTotalSelfBreakRegular(timesheet.selfBreaks, holidayIndices) -
      getRegularLunchHours()
    );
  };

  // Holiday Hours -------------------------------------------------
  const getHolidayHours = () => {
    return workInHoliday?.reduce((a, b) => a + b, 0) ?? 0;
  };

  const getHolidayLunchHours = () => {
    const holidayLunchHours = lunchHours.filter((_, index) => holidayIndices.includes(index));
    return -1 * holidayLunchHours?.reduce((a, b) => a + b, 0) ?? 0;
  };

  const getNetHolidayHours = () => {
    return (
      getHolidayHours() -
      getTotalSelfBreakHoliday(timesheet.selfBreaks, holidayIndices) -
      getHolidayLunchHours()
    );
  };

  // Overtime Hours -------------------------------------------------
  const getOvertimeHours = () => {
    return overtime?.reduce((a, b) => a + b, 0) ?? 0;
  };

  const getNetOvertimeHours = () => {
    return getOvertimeHours() - 0;
  };

  // Total Lunch Hours ----------------------------------------------
  const getTotalLunchHours = () => {
    return -1 * lunchHours?.reduce((a, b) => a + b, 0) ?? 0;
  };

  // Total Hours -----------------------------------------------------
  const getTotalHours = () => {
    const sum = totalHours?.reduce((a, b) => a + b, 0) ?? 0;
    return sum;
  };

  const getNetTotalHours = () => {
    const total = getTotalHours();
    const selfBreaks_regular = getTotalSelfBreakRegular(timesheet.selfBreaks, holidayIndices);
    const selfBreaks_holiday = getTotalSelfBreakHoliday(timesheet.selfBreaks, holidayIndices);
    const lunchHours_regular = getRegularLunchHours();
    const lunchHours_holiday = getHolidayLunchHours();
    return (
      total - selfBreaks_regular - selfBreaks_holiday - lunchHours_regular - lunchHours_holiday
    );
  };

  const handleLunchBreakCheckboxChange = (event, index) => {
    if (isCompleted) {
      props.onNotifyError(
        ERROR,
        "This timesheet is already passed due or locked. Cannot make changes."
      );
      return;
    }

    if (weekendIndices.includes(index)) {
      props.onNotifyError(ERROR, "Cannot enforce lunch break in weekends.");
      return;
    }

    const isChecked = event.target.checked;
    const updatedLunchBreak = [...timesheet.lunchBreak];
    updatedLunchBreak[index] = isChecked;

    const updatedTimesheet = { ...timesheet, lunchBreak: updatedLunchBreak };
    setTimesheet(updatedTimesheet);

    // Notify parent about the updated timesheet
    props.onTimesheetUpdate && props.onTimesheetUpdate(updatedTimesheet);
  };

  return (
    <>
      {/* Summary Table */}
      <div className="ts-summary-table-container">
        <table className="ts-summary-table">
          <tbody>
            <tr>
              <td className="tsmt-label">Regular Net</td>
              <td className="tsmt-value final-no">{getNetRegularHours().toFixed(2)}</td>
              <td className="tsmt-label">Overtime Net</td>
              <td className="tsmt-value final-no">{getNetOvertimeHours().toFixed(2)}</td>
              <td className="tsmt-label">Holiday Net</td>
              <td className="tsmt-value final-no">{getNetHolidayHours().toFixed(2)}</td>
              <td className="tsmt-label">Net</td>
              <td className="tsmt-value">{getNetTotalHours().toFixed(2)}</td>
            </tr>
            <tr>
              <td className="tsmt-label">Regular</td>
              <td className="tsmt-value">{getRegularHours().toFixed(2)}</td>
              <td className="tsmt-label">Overtime</td>
              <td className="tsmt-value">
                {(overtime?.reduce((a, b) => a + b, 0) ?? 0).toFixed(2)}
              </td>
              <td className="tsmt-label">Holiday Work</td>
              <td className="tsmt-value">{getHolidayHours().toFixed(2)}</td>
              <td className="tsmt-label">Total</td>
              <td className="tsmt-value">{getTotalHours().toFixed(2)}</td>
            </tr>
            <tr>
              <td className="tsmt-label">Reg. Self-Break</td>
              <td className="tsmt-value">
                {getTotalSelfBreakRegular(timesheet.selfBreaks, holidayIndices).toFixed(2)}
              </td>
              <td className="tsmt-label">Ovt. Self-Break</td>
              <td className="tsmt-value">0.00</td>
              <td className="tsmt-label">Hld. Self-Break</td>
              <td className="tsmt-value">
                {getTotalSelfBreakHoliday(timesheet.selfBreaks, holidayIndices).toFixed(2)}
              </td>
              <td className="tsmt-label">Self-Break</td>
              <td className="tsmt-value">
                {calculateSelfBreakHours(timesheet.selfBreaks).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="tsmt-label">Regular Lunch *</td>
              <td className="tsmt-value">{getRegularLunchHours().toFixed(2)}</td>
              <td className="tsmt-label">Overtime Lunch *</td>
              <td className="tsmt-value">0.00</td>
              <td className="tsmt-label">Hld. Work Lunch *</td>
              <td className="tsmt-value">{getHolidayLunchHours().toFixed(2)}</td>
              <td className="tsmt-label">Lunch *</td>
              <td className="tsmt-value">{getTotalLunchHours().toFixed(2)}</td>
            </tr>
            <tr>
              <td className="tsmt-label">Sickness</td>
              <td className="tsmt-value">
                {(specialHours.reduce((acc, sh) => acc + sh["Sickness"], 0) ?? 0).toFixed(2)}
              </td>
              <td className="tsmt-label">Holiday</td>
              <td className="tsmt-value">
                {(specialHours.reduce((acc, sh) => acc + sh["Holiday"], 0) ?? 0).toFixed(2)}
              </td>
              <td className="tsmt-label">Vacation</td>
              <td className="tsmt-value">
                {(specialHours.reduce((acc, sh) => acc + sh["Vacation"], 0) ?? 0).toFixed(2)}
              </td>
              <td className="tsmt-label">Unpaid</td>
              <td className="tsmt-value">
                {(specialHours.reduce((acc, sh) => acc + sh["Unpaid"], 0) ?? 0).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colSpan={8} className="tsmt-note">
                * Lunch breaks might be applied when processed for major projects under supervision.
              </td>
            </tr>
          </tbody>
        </table>
        <div className="ts-info-container">
          <table className="ts-info-table">
            <tbody>
              <tr>
                <td className="employee-name">
                  {props.timesheetUser
                    ? props.timesheetUser.firstName + " " + props.timesheetUser.lastName
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="pay-cycle">
                  {fromDate} to {toDate}
                </td>
              </tr>
              <tr>
                <td>{isCompleted ? "Locked" : "Unlocked"}</td>
              </tr>
              <tr>
                <td>{approvedAt ? `Approved` : `Pending for approval`}</td>
              </tr>
              <tr>
                <td>{processedAt ? `Processed` : `Pending for process`}</td>
              </tr>
              <tr>
                <td>
                  {/* {holidayIndices.length !== 0 && (
                    <span>
                      Holiday applied:{' '}
                      {holidayNameIndices
                        .map((index) => holidayNames[index])
                        .join(', ')}
                    </span>
                  )} */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <table className="timesheet-table">
        <thead>
          <tr>
            <th></th>
            {dateHeaders.map((dateString, index) => (
              <th
                className={`
                    ts-column-header 
                    ${weekendIndices.includes(index) ? "weekend" : ""}
                    ${holidayIndices.includes(index) ? "holiday" : ""}
                    `}
                key={index}
              >
                {formatShortDate(dateString)}
              </th>
            ))}
          </tr>
          <tr>
            <th></th>
            {weekDayHeaders.map((dateString, index) => (
              <th
                className={`
                    ts-column-header 
                    ${weekendIndices.includes(index) ? "weekend" : ""}
                    ${holidayIndices.includes(index) ? "holiday" : ""}
                    `}
                key={index}
              >
                {dateString}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {timesheet.data.map((row, rowIndex) => (
            <tr key={rowIndex} className="ts-table-row">
              <th className="ts-row-header">
                {row.headerName.length > 24
                  ? `${row.headerName.substring(0, 21)}...`
                  : row.headerName}
                {row.order <= 900 && (
                  <span className="delete-icon" onClick={() => handleDeleteRow(rowIndex)}>
                    X
                  </span>
                )}
              </th>

              {row.data.map((interval, columnIndex) => {
                const [startTimeStr, endTimeStr] = interval.split("-");
                const computedHours = subtractTimes(startTimeStr, endTimeStr).toFixed(2);
                return (
                  <td key={columnIndex} className="ts-row-data">
                    <div
                      onClick={() => handleOpenModal(rowIndex, columnIndex)}
                      className={`hour-input-div ${
                        weekendIndices.includes(columnIndex) ? "weekend-input" : ""
                      } ${holidayIndices.includes(columnIndex) ? "holiday-input" : ""} ${
                        computedHours !== "0.00" ? "ts-value-not-zero" : ""
                      }`}
                    >
                      {computedHours}
                    </div>
                  </td>
                );
              })}
              {/* logic to compute sum per row */}
              <td className="ts-sum-label">
                {row.data
                  .reduce((a, interval) => {
                    const [startTimeStr, endTimeStr] = interval.split("-");
                    return a + parseFloat(subtractTimes(startTimeStr, endTimeStr));
                  }, 0)
                  .toFixed(2)}
              </td>
            </tr>
          ))}

          <tr>
            <th className="ts-row-header ts-extra-row-header">Self Breaks</th>
            {timesheet.selfBreaks.map((x, index) => {
              const selfBreakHours = calculateColumnSelfBreakHours(x);
              return (
                <td className="ts-row-data" key={"selfBreak-" + index}>
                  <div
                    onClick={() => handleOpenBreakModal(index)}
                    className={`hour-input-div ${selfBreakHours !== 0 ? "neg-no" : ""}`}
                  >
                    {selfBreakHours.toFixed(2)}
                  </div>
                </td>
              );
            })}
            <td className="ts-sum-label">
              {calculateSelfBreakHours(timesheet.selfBreaks).toFixed(2)}
            </td>
          </tr>

          {[
            "Total Hours",
            "Regular Hours",
            "Overtime",
            "Work in Holiday",
            "Special Hours",
            "Lunch Hours",
          ].map((label, labelIndex) => (
            <tr key={label}>
              <th className="ts-row-header ts-extra-row-header">{label}</th>
              {timesheet.data[0]?.data.map((_, columnIndex) => (
                <td className="ts-sum-label" key={columnIndex}>
                  {(labelIndex === 0
                    ? totalHours?.[columnIndex] ?? 0
                    : labelIndex === 1
                    ? regularHours?.[columnIndex] ?? 0
                    : labelIndex === 2
                    ? overtime?.[columnIndex] ?? 0
                    : labelIndex === 3
                    ? workInHoliday?.[columnIndex] ?? 0
                    : labelIndex === 4
                    ? specialHours?.[columnIndex]
                      ? Object.values(specialHours[columnIndex]).reduce((a, b) => a + b, 0)
                      : 0
                    : labelIndex === 5
                    ? lunchHours?.[columnIndex] * -1 ?? 0
                    : 0
                  ).toFixed(2)}
                </td>
              ))}
              <td className="ts-sum-label">
                {(labelIndex === 0
                  ? totalHours?.reduce((a, b) => a + b, 0) ?? 0
                  : labelIndex === 1
                  ? regularHours?.reduce((a, b) => a + b, 0) ?? 0
                  : labelIndex === 2
                  ? overtime?.reduce((a, b) => a + b, 0) ?? 0
                  : labelIndex === 3
                  ? workInHoliday?.reduce((a, b) => a + b, 0) ?? 0
                  : labelIndex === 4
                  ? specialHours?.reduce(
                      (acc, col) => acc + Object.values(col).reduce((a, b) => a + b, 0),
                      0
                    ) ?? 0
                  : labelIndex === 5
                  ? lunchHours?.reduce((a, b) => a + b, 0) ?? 0
                  : 0
                ).toFixed(2)}
              </td>
            </tr>
          ))}
          {(props.currentUser.groupId === ADMIN ||
            props.currentUser.groupId === SUPER_MANAGER ||
            props.currentUser.groupId === MANAGER) && (
            <tr>
              <th className="ts-row-header ts-extra-row-header">Lunch Break Applied</th>
              {timesheet.lunchBreak.map((x, index) => (
                <td className="ts-sum-label" key={"lunch-" + index}>
                  <input
                    type="checkbox"
                    disabled={isCompleted}
                    checked={x}
                    onChange={(e) => handleLunchBreakCheckboxChange(e, index)}
                  />
                </td>
              ))}
              <td className="ts-sum-label"></td>
            </tr>
          )}
        </tbody>
      </table>
      {dialogModal.visible && (
        <Dialog
          visible={dialogModal.visible}
          type={dialogModal.type}
          title={dialogModal.title}
          content={dialogModal.content}
          onConfirm={dialogModal.onConfirm}
          onCancel={dialogModal.onCancel}
        />
      )}
      <TimeEditModal
        visible={editModal.visible}
        interval={
          editModal.visible ? timesheet.data[editModal.rowIndex]?.data[editModal.columnIndex] : ""
        }
        onSave={handleChangeInterval}
        onCancel={handleCloseModal}
      />
      <BreakEditModal
        visible={breakModal.visible}
        breakData={timesheet.selfBreaks}
        columnIndex={breakModal.columnIndex}
        timesheetData={timesheet.data}
        onSave={handleChangeBreak}
        onCancel={handleCloseBreakModal}
      />
    </>
  );
};

export default TimesheetTable;
