export const menuOptions = { ACCESS: 1, ROLE: 2, ROLE_ACCESS: 3, ROLE_ASSIGNMENT: 4 };

export const menuItems = [
  {
    option: menuOptions.ACCESS,
    text: "Accesses",
  },
  {
    option: menuOptions.ROLE,
    text: "Roles",
  },
  {
    option: menuOptions.ROLE_ACCESS,
    text: "Map Access to Role",
  },
  {
    option: menuOptions.ROLE_ASSIGNMENT,
    text: "Role Assignment",
  },
];
