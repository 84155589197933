import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Header from "../../components/Header";
import MessageBox from "../../components/MessageBox";
import { SUCCESS, ERROR } from "../../constants/messageTypes";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ContactDetailPage = ({ history }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [currentContact, setCurrentContact] = useState({
    id: null,
    firstName: "",
    lastName: "",
    title: "",
    personalEmail: "",
    workEmail: "",
    govEmail: "",
    personalPhone: "",
    workPhone: "",
    govPhone: "",
    egyCtName: "",
    egyCtPhone: "",
    egyCtEmail: "",
    egyCtRelationship: "",
    teamId: null,
  });

  const [teams, setTeams] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState("");

  const loadTeams = useCallback(async () => {
    try {
      const response = await axios.get("/api/team");
      setTeams(response.data);
    } catch (error) {
      displayMessage(ERROR, "Error:", error);
    }
  }, []);

  const loadContact = useCallback(async () => {
    if (id) {
      try {
        const response = await axios.get(`/api/contact/${id}`);
        setCurrentContact(response.data);
      } catch (error) {
        displayMessage(ERROR, "Error:", error);
      }
    }
  }, [id]);

  useEffect(() => {
    loadContact();
    loadTeams();
  }, [loadContact, loadTeams]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentContact((prev) => ({ ...prev, [name]: value }));
  };

  const isValidData = (contact) => {
    if (!contact.firstName) {
      displayMessage(ERROR, "First name is required.");
      return false;
    }
    if (!contact.lastName) {
      displayMessage(ERROR, "Last name is required.");
      return false;
    }
    if (!contact.teamId) {
      displayMessage(ERROR, "Team is required.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!isValidData(currentContact)) {
      return;
    }
    if (id) {
      try {
        await axios.put(`/api/contact/${currentContact.id}`, currentContact);
        displayMessage(SUCCESS, "Contact updated successfully.");
        navigate("/manage-contact-list");
      } catch (error) {
        displayMessage(ERROR, "Error:", error);
      }
    } else {
      try {
        await axios.post("/api/contact", currentContact);
        displayMessage(SUCCESS, "New contact created successfully.");
        navigate("/manage-contact-list");
      } catch (error) {
        displayMessage(ERROR, "Error:", error);
      }
    }
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  return (
    <div>
      <Header />
      <div className="large-container">
        <h2>{id ? "Contact Details" : "Create New Contact"}</h2>
        <div>
          <div className="large-container-col">
            <div className="input-group">
              <label>First Name</label>
              <input
                name="firstName"
                value={currentContact.firstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-group">
              <label>Title</label>
              <input name="title" value={currentContact.title} onChange={handleInputChange} />
            </div>
            <div className="input-group">
              <label>Work Email</label>
              <input
                name="workEmail"
                value={currentContact.workEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label>Personal Phone</label>
              <input
                name="personalPhone"
                value={currentContact.personalPhone}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label>Government Phone</label>
              <input name="govPhone" value={currentContact.govPhone} onChange={handleInputChange} />
            </div>
            <div className="input-group">
              <label>Emergency Contact Phone</label>
              <input
                name="egyCtPhone"
                value={currentContact.egyCtPhone}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label>Emergency Contact Relationship</label>
              <input
                name="egyCtRelationship"
                value={currentContact.egyCtRelationship}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="large-container-col">
            <div className="input-group">
              <label>Last Name</label>
              <input
                name="lastName"
                value={currentContact.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-group">
              <label>Personal Email</label>
              <input
                name="personalEmail"
                value={currentContact.personalEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label>Government Email</label>
              <input name="govEmail" value={currentContact.govEmail} onChange={handleInputChange} />
            </div>
            <div className="input-group">
              <label>Work Phone</label>
              <input
                name="workPhone"
                value={currentContact.workPhone}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label>Emergency Contact Name</label>
              <input
                name="egyCtName"
                value={currentContact.egyCtName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label>Emergency Contact Email</label>
              <input
                name="egyCtEmail"
                value={currentContact.egyCtEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label>Team</label>
              <select
                name="teamId"
                value={currentContact.teamId || ""}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Select a team
                </option>
                {teams.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.teamName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="clr"></div>

          <button className="contact-list-btn" onClick={handleSubmit}>
            {currentContact.id ? "Update" : "Create"}
          </button>
        </div>

        {messageText && (
          <MessageBox type={messageType} message={messageText} onClear={() => setMessageText("")} />
        )}
      </div>
    </div>
  );
};

export default ContactDetailPage;
