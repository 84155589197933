import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import { useParams } from 'react-router-dom';

const UserModifyPage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [supervisors, setSupervisors] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState('');
  const [filterSupervisor, setFilterSupervisor] = useState('');
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [peopleGroups, setPeopleGroups] = useState([]);
  const [groupId, setGroupId] = useState('');

  const { id } = useParams();

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`/api/user/${id}`);
        const _userInfo = { ...response.data, password: '' };
        setUserInfo(_userInfo);
        setIsLoading(false);
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    };

    fetchUserInfo();
  }, [id]);

  useEffect(() => {
    fetch('/api/userGroup')
      .then((response) => response.json())
      .then((data) => {
        setPeopleGroups(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await axios.get('/api/user/supervisors');
        const supervisors = response.data;
        setSupervisors(supervisors);
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    };

    fetchSupervisors();
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.supervisorId) setSelectedSupervisor(userInfo.supervisorId);
      setGroupId(userInfo.groupId);
    }
  }, [userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleSupervisorChange = (e) => {
    setSelectedSupervisor(e.target.value);
  };

  const handleFilterSupervisorChange = (e) => {
    setFilterSupervisor(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/user/${userInfo.id}`, {
        ...userInfo,
        supervisorId: selectedSupervisor,
        groupId,
      });
      displayMessage(SUCCESS, 'Updated successfully.');
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  };

  const filteredSupervisors = supervisors.filter((supervisor) =>
    supervisor.firstName.toLowerCase().includes(filterSupervisor.toLowerCase())
  );

  if (isLoading) return <>Loading...</>;

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>Modifying user</h2>
        <div className='input-group'>
          <label htmlFor='firstName'>First Name:</label>
          <input
            type='text'
            name='firstName'
            id='firstName'
            value={userInfo.firstName}
            onChange={handleChange}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='lastName'>Last Name:</label>
          <input
            type='text'
            name='lastName'
            id='lastName'
            value={userInfo.lastName}
            onChange={handleChange}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='email'>Email:</label>
          <input
            type='email'
            name='email'
            id='email'
            value={userInfo.email}
            onChange={handleChange}
            autoComplete='email'
          />
        </div>

        <div className='input-group'>
          <label htmlFor='group'>Group:</label>
          <select
            id='group'
            name='group'
            value={groupId}
            onChange={(e) => setGroupId(e.target.value)}
          >
            <option value=''>Select a Group</option>
            {peopleGroups.map((group) => (
              <option
                key={group.id}
                value={group.id}
              >
                {group.userGroupName}
              </option>
            ))}
          </select>
        </div>

        <div className='input-group'>
          <label htmlFor='supervisorFilter'>Supervisor:</label>
          <input
            type='text'
            name='supervisorId'
            id='supervisorFilter'
            value={filterSupervisor}
            onChange={handleFilterSupervisorChange}
            placeholder='Filter supervisors...'
          />
          <select
            value={selectedSupervisor}
            onChange={handleSupervisorChange}
            id='supervisorDropdown'
          >
            {filteredSupervisors.map((supervisor) => (
              <option
                key={supervisor.id}
                value={supervisor.id}
              >
                {supervisor.firstName} {supervisor.lastName}
              </option>
            ))}
          </select>
        </div>

        <div className='input-group'>
          <label htmlFor='autoTrackTime'>Auto Track Time:</label>
          <select
            id='autoTrackTime'
            name='autoTrackTime'
            value={userInfo.autoTrackTime}
            onChange={handleChange}
          >
            <option value=''>Select Option</option>
            <option value='true'>Enabled</option>
            <option value='false'>Disabled</option>
          </select>
        </div>

        <button onClick={handleUpdate}>Update Account</button>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')}
          />
        )}
      </div>
    </div>
  );
};

export default UserModifyPage;
