import "./styles.css";
import Header from "../../components/Header";
import VerticalMenu from "../../components/VerticalMenu";
import { menuItems } from "./menuConfig";
import AccessComponent from "./Access/AccessComponent";
import { useEffect, useState } from "react";
import { menuOptions } from "./menuConfig";
import RoleComponent from "./Role/RoleComponent";
import RoleAccessComponent from "./RoleAccess/RoleAccessComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccess } from "./Access/accessSlice";
import { fetchRole } from "./Role/roleSlice";
import { fetchRoleAccess } from "./RoleAccess/roleAccessSlice";

const UserRoleAccessPage = () => {
  const dispatch = useDispatch();
  const { accessFirstLoaded } = useSelector((state) => state.access);
  const { roleFirstLoaded } = useSelector((state) => state.role);
  const { roleAccessFirstLoaded } = useSelector((state) => state.roleAccess);

  useEffect(() => {
    if (accessFirstLoaded) {
      console.log("Refetching access");
      dispatch(fetchAccess());
    }
  }, [dispatch, accessFirstLoaded]);

  useEffect(() => {
    if (roleFirstLoaded) {
      console.log("Refetching role");
      dispatch(fetchRole());
    }
  }, [dispatch, roleFirstLoaded]);

  useEffect(() => {
    if (roleAccessFirstLoaded) {
      console.log("Refetching role access");
      dispatch(fetchRoleAccess());
    }
  }, [dispatch, roleAccessFirstLoaded]);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(menuOptions.ACCESS);
  }, []);

  let content;
  switch (selectedOption) {
    case menuOptions.ACCESS:
      content = <AccessComponent />;
      break;
    case menuOptions.ROLE:
      content = <RoleComponent />;
      break;
    case menuOptions.ROLE_ACCESS:
      content = <RoleAccessComponent />;
      break;
    case menuOptions.ROLE_ASSIGNMENT:
      content = "";
      break;
    default:
      content = "";
      break;
  }

  return (
    <div>
      <Header />
      <div className="main-container">
        <div className="mc-left-menu">
          <VerticalMenu
            menuItems={menuItems}
            selectedOption={selectedOption}
            onChangeSelection={setSelectedOption}
          />
        </div>
        <div className="mc-right-content">{content}</div>
        <div className="clr"></div>
      </div>
    </div>
  );
};

export default UserRoleAccessPage;
