import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { useAuth } from '../../contexts/authContext';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';

const MyAccountPage = () => {
  const { currentUser } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [supervisors, setSupervisors] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState(undefined);
  const [filterSupervisor, setFilterSupervisor] = useState('');
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`/api/user/${currentUser.userId}`);
        const _userInfo = response.data;
        setUserInfo(_userInfo);
        setIsLoading(false);
      } catch (error) {
        displayMessage(ERROR, 'Error:' + error.message);
      }
    };

    fetchUserInfo();
  }, [currentUser]);

  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await axios.get('/api/user/supervisors');
        const supervisors = response.data;
        setSupervisors(supervisors);
      } catch (error) {
        displayMessage(ERROR, 'Error:' + error.message);
      }
    };

    fetchSupervisors();
  }, []);

  useEffect(() => {
    if (userInfo) setSelectedSupervisor(userInfo.supervisorId || '');
  }, [userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleSupervisorChange = (e) => {
    setSelectedSupervisor(e.target.value);
  };

  const handleFilterSupervisorChange = (e) => {
    setFilterSupervisor(e.target.value);
  };

  const filteredSupervisors = supervisors.filter((supervisor) =>
    supervisor.firstName.toLowerCase().includes(filterSupervisor.toLowerCase())
  );

  const handleUpdate = async () => {
    try {
      await axios.put(`/api/user/${userInfo.id}`, {
        ...userInfo,
        supervisorId: selectedSupervisor,
      });
      displayMessage(SUCCESS, 'Updated successfully.');
    } catch (error) {
      displayMessage(ERROR, 'Error: ' + error.response.data.error);
    }
  };

  if (isLoading) return <>Loading...</>;

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>My Account</h2>
        <div className='input-group'>
          <label htmlFor='firstName'>First Name:</label>
          <input
            type='text'
            name='firstName'
            id='firstName'
            value={userInfo.firstName}
            onChange={handleChange}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='lastName'>Last Name:</label>
          <input
            type='text'
            name='lastName'
            id='lastName'
            value={userInfo.lastName}
            onChange={handleChange}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='email'>Email:</label>
          <input
            type='email'
            name='email'
            id='email'
            value={userInfo.email}
            onChange={handleChange}
            autoComplete='email'
          />
        </div>
        <div className='input-group'>
          <label htmlFor='supervisorFilter'>Supervisor:</label>
          <input
            type='text'
            name='supervisorId'
            id='supervisorFilter'
            value={filterSupervisor}
            onChange={handleFilterSupervisorChange}
            placeholder='Filter supervisors...'
          />

          <select
            value={selectedSupervisor}
            onChange={handleSupervisorChange}
            id='supervisorDropdown'
          >
            {filteredSupervisors.map((supervisor) => (
              <option
                key={supervisor.id}
                value={supervisor.id}
              >
                {supervisor.firstName} {supervisor.lastName}
              </option>
            ))}
          </select>
        </div>
        <button onClick={handleUpdate}>Update Account</button>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')}
          />
        )}
      </div>
    </div>
  );
};

export default MyAccountPage;
