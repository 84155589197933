export const calculateTotalHours = (data) => {
  const numCols = data[0]?.data.length || 0;
  const totalHoursPerDay = Array(numCols).fill(0);

  data.forEach((row) => {
    row.data.forEach((interval, colIndex) => {
      const [start, end] = interval.split("-");
      totalHoursPerDay[colIndex] += subtractTimes(start, end);
    });
  });

  return totalHoursPerDay;
};

export const calculateRegularHours = (data, weekendIndices, holidayIndices) => {
  return data[0]?.data.map((_, columnIndex) =>
    data.reduce((acc, row) => {
      const [start, end] = row.data[columnIndex].split("-");
      const hours = subtractTimes(start, end);
      // If it's a weekend or holiday, return accumulator as is (effectively adding 0)
      if (weekendIndices.includes(columnIndex) || holidayIndices.includes(columnIndex)) {
        return acc;
      }
      // If it's a regular day and order < 900, add hours to the accumulator
      // but ensure it does not exceed 8 hours per day
      else if (row.order < 900) {
        return Math.min(acc + hours, 8);
      }
      // Default case: return accumulator as is
      else {
        return acc;
      }
    }, 0)
  );
};

export const calculateOvertime = (data, weekendIndices, holidayIndices) => {
  return data[0]?.data.map((_, columnIndex) => {
    // Calculate the total hours for rows with order < 900
    const totalHours = data.reduce((acc, row) => {
      const [start, end] = row.data[columnIndex]?.split("-");
      const hours = subtractTimes(start, end);
      // If it's a weekend and order < 900, add all hours to the accumulator
      if (weekendIndices.includes(columnIndex) && row.order < 900) {
        return acc + hours;
      }
      // If it's a regular day (not weekend or holiday) and order < 900, add the hours
      else if (
        !weekendIndices.includes(columnIndex) &&
        !holidayIndices.includes(columnIndex) &&
        row.order < 900
      ) {
        return acc + hours;
      }
      return acc;
    }, 0);
    // If it's a weekend, return totalHours as is.
    if (weekendIndices.includes(columnIndex)) {
      return totalHours;
    }
    // If it's a regular day, return totalHours - 8 if totalHours > 8, else return 0.
    return Math.max(0, totalHours - 8);
  });
};

export const calculateWorkInHoliday = (data, holidayIndices) => {
  return data[0]?.data.map((_, columnIndex) =>
    data.reduce((acc, row) => {
      if (holidayIndices.includes(columnIndex) && row.order < 900) {
        const [start, end] = row.data[columnIndex].split("-");
        return acc + subtractTimes(start, end);
      }
      return acc;
    }, 0)
  );
};

export const calculateSpecialHours = (data) => {
  return data[0]?.data.map((_, columnIndex) => {
    return data.reduce((acc, row) => {
      if (row.order >= 900) {
        const [start, end] = row.data[columnIndex].split("-");
        // Ensure the hour calculation is treated as a number, not a string.
        const hours = parseFloat(subtractTimes(start, end));
        // Create a new entry for each special type or add to an existing one.
        acc[row.headerName] = (acc[row.headerName] || 0) + hours;
      }
      return acc;
    }, {});
  });
};

export const calculateLunchHours = (data, lunchBreak) => {
  return data[0]?.data.map((_, columnIndex) => {
    // If the lunchBreak value for this column is not true, return 0
    if (!lunchBreak[columnIndex]) return 0;

    const totalHours = data.reduce((acc, row) => {
      if (row.order < 900) {
        const [start, end] = row.data[columnIndex].split("-");
        return acc + subtractTimes(start, end);
      }
      return acc;
    }, 0);

    if (totalHours >= 18) return -1.5;
    else if (totalHours >= 13) return -1;
    else if (totalHours >= 8) return -0.5;
    else return 0;
  });
};

export const calculateColumnSelfBreakHours = (breaks) => {
  return breaks.reduce((total, current) => {
    const [start, end] = current.split("-");
    return total + subtractTimes(start, end);
  }, 0);
};

export const calculateSelfBreakHours = (breakData) => {
  return breakData.reduce((sum, breaks) => {
    return sum + calculateColumnSelfBreakHours(breaks);
  }, 0);
};

export const getTotalSelfBreakRegular = (weekBreaks, holidayIndices) => {
  return weekBreaks.reduce((sum, dayBreaks, columnIndex) => {
    if (!holidayIndices.includes(columnIndex)) {
      return sum + calculateColumnSelfBreakHours(dayBreaks);
    }
    return sum;
  }, 0);
};

export const getTotalSelfBreakHoliday = (weekBreaks, holidayIndices) => {
  return weekBreaks.reduce((sum, dayBreaks, columnIndex) => {
    if (holidayIndices.includes(columnIndex)) {
      return sum + calculateColumnSelfBreakHours(dayBreaks);
    }
    return sum;
  }, 0);
};

export const getTotalHoursAtIndexK = (tsData, k) => {
  let totalHours = 0;

  tsData.forEach((item) => {
    if (item.order < 900) {
      const timeRange = item.data[k];
      const [startTime, endTime] = timeRange.split("-");
      const duration = subtractTimes(startTime, endTime);
      totalHours += duration;
    }
  });

  return totalHours;
};

// Helper function to calculate the difference in hours between two time strings
const subtractTimes = (start, end) => {
  const [startHour, startMinute] = start.split(":").map(Number);
  const [endHour, endMinute] = end.split(":").map(Number);
  return endHour + endMinute / 60 - (startHour + startMinute / 60);
};
