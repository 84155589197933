import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import MessageModal from '../../components/MessageModal';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import { FiUserPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ADMIN, MANAGER, SUPER_MANAGER } from '../../constants/roles';
import { RiLockPasswordLine } from 'react-icons/ri';
import { BsCalendar2Week } from 'react-icons/bs';
import PasswordEditModal from '../../components/PasswordEditModal';
import { useAuth } from '../../contexts/authContext';
import {
  REGISTER,
  USER_MODIFY,
  USER_TIMESHEET,
} from '../../constants/routeUrl';
import { formatNYTime } from '../../utils/DateUtils';

const UsersPage = () => {
  const currentUser = useAuth().currentUser;
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [editModal, setEditModal] = useState({
    visible: false,
    user: null,
  });
  const [messageBoxModal, setMessageBoxModal] = useState({
    visible: false,
    message: '',
  });
  const [searchQuery, setSearchQuery] = useState('');

  const loadUsers = useCallback(async () => {
    try {
      const response = await axios.get('/api/user');
      const sortedUsers = response.data.sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
      );
      setUsers(sortedUsers);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const handleCreateNewUser = async () => {
    navigate(`/${REGISTER}`);
  };

  const getFilteredUsers = useCallback(() => {
    if (!searchQuery) return users;
    return users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, users]);

  const handleEdit = (user) => {
    if (currentUser.groupId === SUPER_MANAGER && user.groupId === ADMIN) {
      displayMessage(ERROR, 'You cannot modify Admin account.');
      return;
    }
    navigate(`/${USER_MODIFY}/${user.id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/user/${id}`);
      loadUsers();
      displayMessage(SUCCESS, '1 user deleted.');
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  };

  const handleOpenPasswordModal = (user) => {
    if (currentUser.groupId === SUPER_MANAGER && user.groupId === ADMIN) {
      displayMessage(ERROR, 'You cannot modify Admin account.');
      return;
    }
    setEditModal({ visible: true, user });
  };

  const handleClosePasswordModal = () => {
    setEditModal({ visible: false, user: null });
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleSave = async (userId, newPassword) => {
    try {
      const response = await axios.put(`/api/user/change-password/${userId}`, {
        password: newPassword,
      });
      if (response.data.success) {
        displayMessage(SUCCESS, 'Changed password successfully.');
        handleClosePasswordModal();
      } else {
        displayMessage(ERROR, 'Error: ' + response.data.error);
      }
    } catch (err) {
      displayMessage(ERROR, 'Error: ' + err);
    }
  };

  const handleResetPassword = async (userId) => {
    try {
      const response = await axios.put(
        `/api/user/reset-to-default-password/${userId}`
      );
      if (response.data.success) {
        displayMessage(SUCCESS, 'Password reset successfully.');
        handleClosePasswordModal();
      } else {
        displayMessage(ERROR, 'Error: ' + response.data.error);
      }
    } catch (err) {
      displayMessage(ERROR, 'Error: ' + err);
    }
  };

  const handleResetSimplePassword = async (userId) => {
    try {
      const response = await axios.get(
        `/api/user/reset-simple-password/${userId}`
      );
      if (response.data.success) {
        handleOpenMessageModal('New password is: ' + response.data.newPassword);
        handleClosePasswordModal();
      } else {
        displayMessage(ERROR, 'Error: ' + response.data.error);
      }
    } catch (err) {
      displayMessage(ERROR, 'Error: ' + err);
    }
  };

  const handleOpenUserTimesheet = (user) => {
    navigate(`/${USER_TIMESHEET}/${user.id}`);
  };

  const handleOpenMessageModal = (msg) => {
    setMessageBoxModal({ visible: true, message: msg });
  };

  const handleCloseMessageModal = () => {
    setMessageBoxModal({ visible: false, message: '' });
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container us-list-container'>
        <h2>Users</h2>
        <div className='mu-controller-container'>
          <button onClick={handleCreateNewUser}>
            <FiUserPlus
              className='button-img'
              size={24}
            />
            New User
          </button>
          <div className='search-container'>
            <input
              type='text'
              placeholder='Search by name...'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className='item-list'>
          {getFilteredUsers().map((user) => (
            <div
              className='g-row'
              key={user.id}
            >
              <span className='g-col-A'>
                <div
                  className={`us-name ${
                    user.groupId === ADMIN ? 'up-admin up-special' : ''
                  } ${
                    user.groupId === SUPER_MANAGER
                      ? 'up-super-manager up-special'
                      : ''
                  } ${
                    user.groupId === MANAGER ? 'up-manager up-special' : ''
                  } `}
                >
                  {user.firstName} {user.lastName}
                </div>
                <div className='us-email'>{user.email}</div>
                <div className='us-email'>
                  Last seen: {formatNYTime(user.lastLoggedIn)}
                </div>
              </span>
              <div className='g-col-B'>
                <div className='g-svg_wrapper'>
                  {(currentUser.groupId === ADMIN ||
                    currentUser.groupId === SUPER_MANAGER) && (
                    <RiLockPasswordLine
                      className='icon-controller'
                      size={24}
                      onClick={() => handleOpenPasswordModal(user)}
                    >
                      Reset Password
                    </RiLockPasswordLine>
                  )}
                  {[MANAGER, SUPER_MANAGER, ADMIN].includes(
                    currentUser.groupId
                  ) && (
                    <BsCalendar2Week
                      className='icon-controller'
                      onClick={() => handleOpenUserTimesheet(user)}
                    >
                      Edit
                    </BsCalendar2Week>
                  )}
                  {(currentUser.groupId === ADMIN ||
                    currentUser.groupId === SUPER_MANAGER) && (
                    <FaEdit
                      className='icon-controller'
                      onClick={() => handleEdit(user)}
                    >
                      Edit
                    </FaEdit>
                  )}
                  {currentUser.groupId === ADMIN && (
                    <FaRegTrashAlt
                      className='icon-controller'
                      onClick={() => handleDelete(user.id)}
                    >
                      Delete
                    </FaRegTrashAlt>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')} // Clear the message after it disappears
          />
        )}
      </div>
      <PasswordEditModal
        visible={editModal.visible}
        user={editModal.user}
        onResetPassword={handleResetPassword}
        onSave={handleSave}
        onCancel={handleClosePasswordModal}
        onResetSimplePassword={handleResetSimplePassword}
      />
      <MessageModal
        visible={messageBoxModal.visible}
        message={messageBoxModal.message}
        onCancel={handleCloseMessageModal}
      />
    </div>
  );
};

export default UsersPage;
