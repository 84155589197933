import React, { useState } from 'react';
import './styles.css';

const PasswordEditModal = ({
  visible,
  user,
  onResetPassword,
  onResetSimplePassword,
  onSave,
  onCancel,
}) => {
  const [password, setPassword] = useState('');
  const { id, firstName, lastName } = user || {};

  const handleAction = (e, action) => {
    e.preventDefault();
    if (id) action(id);
    setPassword('');
  };

  const handleCancelModal = () => {
    setPassword('');
    onCancel();
  };

  if (!visible) return null;

  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <h2>Modifying Password</h2>
        <div className='input-group'>
          <label htmlFor='password'>
            New Password for {firstName} {lastName}
          </label>
          <input
            type='password' // Changed to type password for security
            name='password'
            id='password'
            className='pem-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          onClick={(e) =>
            handleAction(e, (userId) => password && onSave(userId, password))
          }
        >
          Save Custom
        </button>
        <button onClick={(e) => handleAction(e, onResetPassword)}>
          Reset Default
        </button>
        <button onClick={(e) => handleAction(e, onResetSimplePassword)}>
          Reset Simple
        </button>
        <button onClick={handleCancelModal}>Cancel</button>
      </div>
    </div>
  );
};

export default PasswordEditModal;
