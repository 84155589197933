import React, { useState, useEffect } from "react";
import { subtractTimes } from "../../utils/DateUtils";
import "./styles.css";

const TimeEditModal = ({ visible, interval, onSave, onCancel }) => {
  const convert24To12 = (hour) => {
    const hr = parseInt(hour, 10);
    if (hr === 0) return [12, "AM"];
    else if (hr === 12) return [12, "PM"];
    else if (hr > 12) return [hr - 12, "PM"];
    else return [hr, "AM"];
  };

  const convert12To24 = (hour, period) => {
    let hr = parseInt(hour, 10);
    if (period === "PM" && hr !== 12) hr += 12;
    else if (period === "AM" && hr === 12) hr = 0;
    return hr.toString().padStart(2, "0");
  };

  const [fromHour, setFromHour] = useState("00");
  const [fromMinute, setFromMinute] = useState("00");
  const [fromAMPM, setFromAMPM] = useState("AM");

  const [toHour, setToHour] = useState("00");
  const [toMinute, setToMinute] = useState("00");
  const [toAMPM, setToAMPM] = useState("AM");

  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    const fromTime = interval?.split("-")[0] || "00:00";
    const toTime = interval?.split("-")[1] || "00:00";
    const [fromHour24 = "00", fromMin24 = "00"] = fromTime.split(":");
    const [toHour24 = "00", toMin24 = "00"] = toTime.split(":");
    const fromHour12 = convert24To12(fromHour24)[0].toString().padStart(2, "0");
    const fromMinute12 = fromMin24;
    const fromPeriod12 = convert24To12(fromHour24)[1];
    const toHour12 = convert24To12(toHour24)[0].toString().padStart(2, "0");
    const toMinute12 = toMin24;
    const toPeriod12 = convert24To12(toHour24)[1];
    setFromHour(fromHour12);
    setFromMinute(fromMinute12);
    setFromAMPM(fromPeriod12);
    setToHour(toHour12);
    setToMinute(toMinute12);
    setToAMPM(toPeriod12);
  }, [interval]);

  const isValidInterval = (from, to) => {
    const [fromTime, fromPeriod] = from.split(" ");
    const [fromHour, fromMinute] = fromTime.split(":");
    const from24Hr = `${convert12To24(fromHour, fromPeriod)}:${fromMinute}`;

    const [toTime, toPeriod] = to.split(" ");
    const [toHour, toMinute] = toTime.split(":");
    const to24Hr = `${convert12To24(toHour, toPeriod)}:${toMinute}`;

    const diff = subtractTimes(from24Hr, to24Hr);
    if (diff < 0) {
      setValidationError("Invalid time interval. Try again!");
      return false;
    } else {
      setValidationError("");
      return true;
    }
  };

  const handleSave = () => {
    const fromTime = `${convert12To24(fromHour, fromAMPM)}:${fromMinute}`;
    const toTime = `${convert12To24(toHour, toAMPM)}:${toMinute}`;
    if (
      !isValidInterval(`${fromHour}:${fromMinute} ${fromAMPM}`, `${toHour}:${toMinute} ${toAMPM}`)
    ) {
      return;
    }
    const newInterval = `${fromTime}-${toTime}`;
    onSave(newInterval);
  };

  if (!visible) return null;

  const quickSetFour = () => {
    const _fromHour = "07";
    const _fromMinute = "00";
    const _fromAMPM = "AM";
    const _toHour = "11";
    const _toMinute = "00";
    const _toAMPM = "AM";
    const fromTime = `${convert12To24(_fromHour, _fromAMPM)}:${_fromMinute}`;
    const toTime = `${convert12To24(_toHour, _toAMPM)}:${_toMinute}`;
    if (
      !isValidInterval(
        `${_fromHour}:${_fromMinute} ${_fromAMPM}`,
        `${_toHour}:${_toMinute} ${_toAMPM}`
      )
    ) {
      return;
    }
    const newInterval = `${fromTime}-${toTime}`;
    onSave(newInterval);
  };

  const quickSetEight = () => {
    const _fromHour = "07";
    const _fromMinute = "00";
    const _fromAMPM = "AM";
    const _toHour = "03";
    const _toMinute = "00";
    const _toAMPM = "PM";
    const fromTime = `${convert12To24(_fromHour, _fromAMPM)}:${_fromMinute}`;
    const toTime = `${convert12To24(_toHour, _toAMPM)}:${_toMinute}`;
    if (
      !isValidInterval(
        `${_fromHour}:${_fromMinute} ${_fromAMPM}`,
        `${_toHour}:${_toMinute} ${_toAMPM}`
      )
    ) {
      return;
    }
    const newInterval = `${fromTime}-${toTime}`;
    onSave(newInterval);
  };

  const quickReset = () => {
    const _fromHour = "07";
    const _fromMinute = "00";
    const _fromAMPM = "AM";
    const _toHour = "07";
    const _toMinute = "00";
    const _toAMPM = "AM";
    const fromTime = `${convert12To24(_fromHour, _fromAMPM)}:${_fromMinute}`;
    const toTime = `${convert12To24(_toHour, _toAMPM)}:${_toMinute}`;
    if (
      !isValidInterval(
        `${_fromHour}:${_fromMinute} ${_fromAMPM}`,
        `${_toHour}:${_toMinute} ${_toAMPM}`
      )
    ) {
      return;
    }
    const newInterval = `${fromTime}-${toTime}`;
    onSave(newInterval);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="float-left">Modifying Time</h2>
        <button className="btnReset" onClick={quickReset}>
          Clear
        </button>
        <div className="clr"></div>
        <hr></hr>
        <table>
          <tbody>
            <tr>
              <td>
                <label>From:</label>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={fromHour}
                  onChange={(e) => setFromHour(e.target.value)}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
                    <option value={num.toString().padStart(2, "0")} key={num}>
                      {num.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={fromMinute}
                  onChange={(e) => setFromMinute(e.target.value)}
                >
                  {["00", "15", "30", "45"].map((num) => (
                    <option value={num} key={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={fromAMPM}
                  onChange={(e) => setFromAMPM(e.target.value)}
                >
                  {["AM", "PM"].map((period) => (
                    <option value={period} key={period}>
                      {period}
                    </option>
                  ))}
                </select>
              </td>
              <td className="ts-divider">
                <label>To:</label>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={toHour}
                  onChange={(e) => setToHour(e.target.value)}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
                    <option value={num.toString().padStart(2, "0")} key={num}>
                      {num.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={toMinute}
                  onChange={(e) => setToMinute(e.target.value)}
                >
                  {["00", "15", "30", "45"].map((num) => (
                    <option value={num} key={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={toAMPM}
                  onChange={(e) => setToAMPM(e.target.value)}
                >
                  {["AM", "PM"].map((period) => (
                    <option value={period} key={period}>
                      {period}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="8">
                {validationError && <div className="time-modal-error-msg">{validationError}</div>}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="time-modal-btn-container">
          <button className="time-modal-btn time-modal-btn-left" onClick={quickSetFour}>
            Quick-4
          </button>
          <button className="time-modal-btn time-modal-btn-left" onClick={quickSetEight}>
            Quick-8
          </button>
          <button className="time-modal-btn time-modal-btn-left" onClick={handleSave}>
            Save
          </button>
          <button className="time-modal-btn time-modal-btn-left" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeEditModal;
