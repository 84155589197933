import Header from "../../../components/Header";

const DirectoryPolicyPage = () => {
  return (
    <div>
      <Header />
      <div className="contact-list-container">
        <h1>Privacy Policy for DFD Directory</h1>
        <p>
          <strong>Last Updated:</strong> 11/13/2023
        </p>

        <h3>1. Introduction:</h3>
        <p>
          DFD Contacts is an internal application developed for Dynamic Facility Designs LLC (DFD).
          This Privacy Policy outlines our practices concerning the handling of information through
          the use of our app. DFD Contacts is designed primarily for viewing information and does
          not permit users to input or submit any information.
        </p>

        <h3>2. Information Collection:</h3>
        <p>
          The contact information provided in DFD Contacts consists of details collected and shared
          with the consent of DFD employees. All employees have signed an agreement permitting their
          contact details to be collected and shared internally within DFD LLC for operational
          purposes.
        </p>

        <h3>3. Use of Information:</h3>
        <p>
          As DFD Contacts does not collect user information, there are no practices related to the
          use of personal data from the app users. The app is used exclusively for viewing the
          contact details of employees, as per the consent and agreement signed by the employees.
        </p>

        <h3>4. Data Sharing and Disclosure:</h3>
        <p>
          No personal data is collected from the app users. The employee contact information
          available in the app is shared internally within DFD LLC and is not disclosed to any
          external parties.
        </p>

        <h3>5. Data Security:</h3>
        <p>
          We are committed to ensuring the security of the data contained within the app, which
          includes employee contact details. Appropriate measures are taken to protect this
          information from unauthorized access or disclosure.
        </p>

        <h3>6. Changes to This Privacy Policy:</h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify users of any changes by
          posting the new Privacy Policy on this page.
        </p>

        <h3>7. Contact Us:</h3>
        <p>
          For any questions or concerns about this Privacy Policy, please contact us at 703-705-9000
          (Louis Tran - IT Director).
        </p>
      </div>
    </div>
  );
};

export default DirectoryPolicyPage;
