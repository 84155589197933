import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { LOGIN } from '../../constants/routeUrl';

const ProtectedRoute = ({ children, roles, ...props }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  // If no user is authenticated, navigate to the LOGIN page.
  if (!currentUser) {
    return (
      <Navigate
        to={'/' + LOGIN}
        state={{ from: location }}
        replace
      />
    );
  }

  // If roles are provided and the user's groupId is not in those roles, restrict access.
  if (roles && !roles.includes(currentUser.groupId)) {
    // You can Navigate to a 403 Forbidden page, or just not render the component, etc.
    return <div>Access Denied</div>;
  }

  return React.cloneElement(children, props);
};

export default ProtectedRoute;
