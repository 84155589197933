import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { useAuth } from '../../contexts/authContext';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';

const ChangePasswordPage = () => {
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [password, setPassword] = useState('');

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`/api/user/change-my-password`, {
        password,
      });
      if (response.status === 200) {
        displayMessage(SUCCESS, 'Updated password successfully.');
        setPassword('');
      } else displayMessage(ERROR, 'Error:', response.data.error);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error.response.data.error);
    }
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>Change Password</h2>
        <div className='input-group'>
          <label htmlFor='password'>New Password:</label>
          <input
            type='password'
            name='password'
            id='password'
            value={password}
            onChange={handleChange}
          />
        </div>
        <button onClick={handleUpdate}>Update Password</button>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')}
          />
        )}
      </div>
    </div>
  );
};

export default ChangePasswordPage;
