import '../resources/css/general.css';
import './styles.css';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { MANAGE_USER } from '../../constants/routeUrl';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import MessageBox from '../../components/MessageBox';

function RegisterPage() {
  const navigate = useNavigate();

  const [supervisors, setSupervisors] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [filterSupervisor, setFilterSupervisor] = useState('');

  const [peopleGroups, setPeopleGroups] = useState([]);

  useEffect(() => {
    fetch('/api/userGroup')
      .then((response) => response.json())
      .then((data) => {
        setPeopleGroups(data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await axios.get('/api/user/supervisors');
        const supervisors = response.data;
        setSupervisors(supervisors);
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    };

    fetchSupervisors();
  }, []);

  useEffect(() => {
    if (supervisors.length !== 0) setSelectedSupervisor(supervisors[0].id);
  }, [supervisors]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Input validation
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !email.trim() ||
      !password.trim() ||
      !groupId
    ) {
      displayMessage(ERROR, 'All fields are required.');
      return;
    }

    // Form data
    const userData = {
      firstName,
      lastName,
      groupId,
      supervisorId: selectedSupervisor,
      email,
      password,
    };

    axios
      .post('/api/user/register', userData)
      .then((response) => {
        displayMessage(SUCCESS, 'Ok');
        navigate(`/${MANAGE_USER}`);
      })
      .catch((err) => {
        displayMessage(ERROR, 'Error:', err);
      });
  };

  const handleSupervisorChange = (e) => {
    setSelectedSupervisor(e.target.value);
  };

  const handleFilterSupervisorChange = (e) => {
    setFilterSupervisor(e.target.value);
  };

  const filteredSupervisors = supervisors.filter((supervisor) =>
    supervisor.firstName.toLowerCase().includes(filterSupervisor.toLowerCase())
  );

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  return (
    <div>
      <Header />
      <form className='registration-form'>
        <h2>Register</h2>

        <div className='input-group'>
          <label htmlFor='firstName'>First Name:</label>
          <input
            type='text'
            id='firstName'
            name='firstName'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className='input-group'>
          <label htmlFor='lastName'>Last Name:</label>
          <input
            type='text'
            id='lastName'
            name='lastName'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className='input-group'>
          <label htmlFor='email'>Email:</label>
          <input
            type='email'
            id='email'
            name='email'
            value={email}
            autoComplete='username'
            onChange={(e) => setemail(e.target.value)}
          />
        </div>

        <div className='input-group'>
          <label htmlFor='group'>Group:</label>
          <select
            id='group'
            name='group'
            value={groupId}
            onChange={(e) => setGroupId(e.target.value)}
          >
            <option value=''>Select a Group</option>
            {peopleGroups.map((group) => (
              <option
                key={group.id}
                value={group.id}
              >
                {group.userGroupName}
              </option>
            ))}
          </select>
        </div>

        <div className='input-group'>
          <label htmlFor='supervisorFilter'>Supervisor:</label>
          <input
            type='text'
            name='supervisorId'
            id='supervisorFilter'
            value={filterSupervisor}
            onChange={handleFilterSupervisorChange}
            placeholder='Filter supervisors...'
          />
          <select
            value={selectedSupervisor}
            onChange={handleSupervisorChange}
            id='supervisorDropdown'
          >
            {filteredSupervisors.map((supervisor) => (
              <option
                key={supervisor.id}
                value={supervisor.id}
              >
                {supervisor.firstName} {supervisor.lastName}
              </option>
            ))}
          </select>
        </div>

        <div className='input-group'>
          <label htmlFor='password'>Password:</label>
          <input
            type='password'
            id='password'
            name='password'
            value={password}
            autoComplete='current-password'
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button onClick={handleSubmit}>Register</button>
      </form>

      {messageText && (
        <MessageBox
          type={messageType}
          message={messageText}
          onClear={() => setMessageText('')}
        />
      )}
    </div>
  );
}

export default RegisterPage;
