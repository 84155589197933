import "../resources/css/general.css";
import "./styles.css";

import Header from "../../components/Header";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import MessageBox from "../../components/MessageBox";
import { SUCCESS, ERROR } from "../../constants/messageTypes";
import { useAuth } from "../../contexts/authContext";
import ContactItem from "../../components/ContactItem";
import { useNavigate } from "react-router-dom";

const ContactListPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState("");

  const fetchContacts = useCallback(async () => {
    try {
      const response = await axios.get("/api/contact");
      const sortedList = response.data.slice().sort((a, b) => {
        if (a.teamId < b.teamId) return -1;
        if (a.teamId > b.teamId) return 1;
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        return 0;
      });
      setContacts(sortedList);
    } catch (error) {
      displayMessage(ERROR, "Error fetching contacts:", error);
    }
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleEdit = (id) => {
    navigate(`/manage-contact-detail/${id}`);
  };

  const handleCreateNew = () => {
    navigate("/manage-contact-detail");
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`/api/contact/${id}`);
      if (response.status === 200) {
        const updatedContacts = contacts.filter((contact) => contact.id !== id);
        setContacts(updatedContacts);
      }
      displayMessage(SUCCESS, "1 contact deleted.");
    } catch (error) {
      displayMessage(ERROR, "Error:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="contact-list-container">
        {currentUser && (
          <div>
            <button onClick={handleCreateNew}>New Contact</button>
          </div>
        )}
        <div className="item-list">
          {contacts.map((contact) => (
            <ContactItem
              key={contact.id}
              contact={contact}
              currentUser={currentUser}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          ))}
        </div>

        {messageText && (
          <MessageBox type={messageType} message={messageText} onClear={() => setMessageText("")} />
        )}
      </div>
    </div>
  );
};

export default ContactListPage;
