import "./styles.css";
import { CONFIRM, WARNING, ERROR } from "./dialogTypes";

const Dialog = ({ visible, type, title, content, onConfirm, onCancel }) => {
  let typeClass = "";
  switch (type) {
    case CONFIRM:
      typeClass = "cd-confirm";
      break;
    case WARNING:
      typeClass = "cd-warning";
      break;
    case ERROR:
      typeClass = "cd-error";
      break;
    default:
      typeClass = "";
      break;
  }

  if (!visible) return null;
  else
    return (
      <div className="cd-overlay">
        <div className={`cd-container ${typeClass}`}>
          <div className="cd-title">{title}</div>
          <div className="cd-content">{content}</div>
          <div className="cd-actions">
            <button onClick={onConfirm}>Continue</button>
            <button onClick={onCancel}>Cancel</button>
          </div>
        </div>
      </div>
    );
};

export default Dialog;
