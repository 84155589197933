import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk actions
export const fetchRole = createAsyncThunk("role/fetchRole", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get("/api/role");
    return response.data.data; // Assuming the data is in response.data.data
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const createRole = createAsyncThunk(
  "role/createRole",
  async (newItem, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/role", newItem);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateRole = createAsyncThunk(
  "role/updateRole",
  async (updateItem, { rejectWithValue }) => {
    try {
      await axios.put(`/api/role/${updateItem.id}`, updateItem);
      return updateItem; // Assuming you want to return the updated item
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteRole = createAsyncThunk("role/deleteRole", async (id, { rejectWithValue }) => {
  try {
    await axios.delete(`/api/role/${id}`);
    return id; // Return the id of the deleted item
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Slice with extraReducers to handle all async actions
const roleSlice = createSlice({
  name: "role",
  initialState: {
    roleList: [],
    loading: false,
    error: null,
    roleFirstLoaded: true,
  },
  reducers: {
    // Synchronous action reducers here...
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchRole actions
      .addCase(fetchRole.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.roleFirstLoaded = false;
      })
      .addCase(fetchRole.fulfilled, (state, action) => {
        state.roleList = action.payload;
        state.loading = false;
        state.roleFirstLoaded = false;
      })
      .addCase(fetchRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.roleFirstLoaded = false;
      })
      // Handle createRole actions
      .addCase(createRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.roleList.push(action.payload);
        state.loading = false;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Handle updateRole actions
      .addCase(updateRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        const index = state.roleList.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.roleList[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Handle deleteRole actions
      .addCase(deleteRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.roleList = state.roleList.filter((item) => item.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default roleSlice.reducer;
