import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import { FaTrash, FaEdit } from 'react-icons/fa';

const DefaultTimesheetHeaderPage = () => {
  const [headers, setHeaders] = useState([]);
  const [currentHeader, setCurrentHeader] = useState({
    id: null,
    headerName: '',
    order: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');

  const loadHeaders = useCallback(async () => {
    try {
      const response = await axios.get('/api/defaultTimesheetHeader');
      const sortedHeaders = response.data.sort((a, b) => a.order - b.order);
      setHeaders(sortedHeaders);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  }, []);

  useEffect(() => {
    loadHeaders();
  }, [loadHeaders]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentHeader((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (currentHeader.headerName.length === 0) {
      displayMessage(ERROR, 'Header name cannot be blank.');
      return;
    }

    if (currentHeader.headerName.length === 0) {
      displayMessage(ERROR, 'Order cannot be blank.');
      return;
    }

    const parsedOrder = parseInt(currentHeader.order);
    if (!Number.isInteger(parsedOrder) || parsedOrder <= 0) {
      displayMessage(ERROR, 'Order must be a positive integer.');
      return;
    }

    if (isEditing) {
      try {
        await axios.put(
          `/api/defaultTimesheetHeader/${currentHeader.id}`,
          currentHeader
        );
        loadHeaders();
        resetForm();
        displayMessage(SUCCESS, '1 header updated.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    } else {
      try {
        await axios.post('/api/defaultTimesheetHeader', currentHeader);
        loadHeaders();
        resetForm();
        displayMessage(SUCCESS, 'New header created.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    }
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const resetForm = () => {
    setCurrentHeader({ id: null, headerName: '', order: null });
    setIsEditing(false);
  };

  const handleEdit = (header) => {
    setCurrentHeader(header);
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/defaultTimesheetHeader/${id}`);
      loadHeaders();
      displayMessage(SUCCESS, '1 header deleted.');
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>Default Timesheet Headers</h2>
        <div>
          <div className='input-group'>
            <label>Header Name</label>
            <input
              name='headerName'
              value={currentHeader.headerName}
              onChange={handleInputChange}
              placeholder='Default Header'
            />
            <label>Order</label>
            <input
              type='number'
              name='order'
              value={currentHeader.order || ''}
              onChange={handleInputChange}
              placeholder='Order'
            />
          </div>
          <button onClick={handleSubmit}>
            {isEditing ? 'Update' : 'Create'}
          </button>
          {isEditing && <button onClick={resetForm}>Cancel</button>}
        </div>
        <div className='item-list'>
          {headers.map((header) => (
            <div
              className='g-row'
              key={header.id}
            >
              <div className='g-col-A'>
                <span className='one-value-input'>{header.headerName}</span>
                <span className='one-value-input'>{header.order}</span>
              </div>
              <div className='g-col-B'>
                <FaEdit
                  className='icon-controller'
                  onClick={() => handleEdit(header)}
                />
                <FaTrash
                  className='icon-controller'
                  onClick={() => handleDelete(header.id)}
                />
              </div>
            </div>
          ))}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')}
          />
        )}
      </div>
    </div>
  );
};

export default DefaultTimesheetHeaderPage;
