import "./styles.css";

import React from "react";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import { SUCCESS, ERROR } from "../../constants/messageTypes";
import { formatDate } from "../../utils/DateUtils";

const PayPeriodRecord = ({ payPeriod, onDeleted, displayMessage }) => {
  const handleDelete = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.delete(`/api/payPeriod/${payPeriod.id}`);

      if (response.status === 200 || response.status === 204) {
        onDeleted(payPeriod.id);
        displayMessage(SUCCESS, "1 record deleted.");
      } else {
        displayMessage(ERROR, "Unexpected status code: " + response.status);
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const currentRecordClass = payPeriod.isCurrent ? "current-record" : "";
  const payDate = new Date(payPeriod.payDate);
  const dueDate = payDate.setDate(payDate.getDate() - 10);

  return (
    <div className="payperiod-record">
      <div className={`view-mode ${currentRecordClass}`}>
        <span className="from-to">
          <span>{payPeriod.fromDate}</span> : <span>{payPeriod.toDate}</span>
        </span>
        <span className="status">
          <span>{formatDate(dueDate)}</span>
        </span>
        <span className="pay-date">
          <span>{payPeriod.payDate}</span>
        </span>
        {onDeleted !== null && (
          <span className="controller">
            <FaTrash onClick={handleDelete} className="icon-controller" />
          </span>
        )}
      </div>
    </div>
  );
};

export default PayPeriodRecord;
