import React from "react";
import { formatDate } from "../../utils/DateUtils";

const PayPeriodRecord = ({ payPeriod }) => {
  const currentRecordClass = payPeriod.isCurrent ? "current-record" : "";
  const payDate = new Date(payPeriod.payDate);
  const dueDate = payDate.setDate(payDate.getDate() - 10);

  return (
    <div className="payperiod-record">
      <div className={`view-mode ${currentRecordClass}`}>
        <span className="from-to">
          <span>{payPeriod.fromDate}</span> : <span>{payPeriod.toDate}</span>
        </span>
        <span className="status">
          <span>{formatDate(dueDate)}</span>
        </span>
        <span className="pay-date pc-pay-date">
          <span>{payPeriod.payDate}</span>
        </span>
      </div>
    </div>
  );
};

export default PayPeriodRecord;
