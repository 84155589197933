import React from 'react';
import { formatDate } from '../../utils/DateUtils';
// create, delete, unlock/lock, approve/disapprove, process/unprocess
import {
  BsPlusCircle,
  BsTrash3,
  BsLock,
  BsCheckSquare,
  BsCheckSquareFill,
  BsCalendar2Date,
} from 'react-icons/bs';
import { ADMIN, MANAGER, SUPER_MANAGER } from '../../constants/roles';

const PayPeriodUserRecord = ({
  payPeriod,
  userTimesheet,
  groupId,
  userId,
  onCreate,
  onDelete,
  onLock,
  onApprove,
  onProcess,
  onView,
}) => {
  const currentRecordClass = payPeriod.isCurrent ? 'current-record' : '';
  const payDate = new Date(payPeriod.payDate);
  const dueDate = payDate.setDate(payDate.getDate() - 5);

  const matchedTS = userTimesheet.filter(
    (x) => x.fromDate === payPeriod.fromDate
  )[0];

  const avaiStatus = matchedTS ? 'Available' : '';
  const lockStatus = matchedTS
    ? matchedTS.isCompleted
      ? 'Locked'
      : 'Unlocked'
    : '';
  const approvalStatus = matchedTS
    ? matchedTS.approvedAt
      ? 'Approved'
      : 'Pending'
    : '';
  const processStatus = matchedTS
    ? matchedTS.processedAt
      ? 'Processed'
      : 'Pending'
    : '';

  return (
    <tr>
      <td>
        {payPeriod.fromDate} : {payPeriod.toDate}
      </td>
      <td>{formatDate(dueDate)}</td>
      <td>{avaiStatus}</td>
      <td className={!matchedTS?.isCompleted ? 'utrt-pending' : ''}>
        {lockStatus}
      </td>
      <td className={!matchedTS?.approvedAt ? 'utrt-pending' : ''}>
        {approvalStatus}
      </td>
      <td className={!matchedTS?.processedAt ? 'utrt-pending' : ''}>
        {processStatus}
      </td>
      <td>
        {!matchedTS ? (
          <BsPlusCircle
            className='icon-controller'
            onClick={() =>
              onCreate(payPeriod.fromDate, payPeriod.toDate, userId)
            }
          >
            Add
          </BsPlusCircle>
        ) : (
          <span>
            {groupId === ADMIN && (
              <BsTrash3
                className='icon-controller'
                onClick={() =>
                  onDelete(payPeriod.fromDate, payPeriod.toDate, userId)
                }
              >
                Trash
              </BsTrash3>
            )}
            {[MANAGER, SUPER_MANAGER, ADMIN].includes(groupId) && (
              <BsLock
                className='icon-controller'
                onClick={() =>
                  onLock(
                    payPeriod.fromDate,
                    payPeriod.toDate,
                    userId,
                    matchedTS.isCompleted
                  )
                }
              >
                Lock/Unlock
              </BsLock>
            )}
            {[MANAGER, SUPER_MANAGER, ADMIN].includes(groupId) && (
              <BsCheckSquare
                className='icon-controller'
                onClick={() =>
                  onApprove(
                    payPeriod.fromDate,
                    payPeriod.toDate,
                    userId,
                    matchedTS.approvedAt
                  )
                }
              >
                Approve/Disapprove
              </BsCheckSquare>
            )}
            {[SUPER_MANAGER, ADMIN].includes(groupId) && (
              <BsCheckSquareFill
                className='icon-controller'
                onClick={() =>
                  onProcess(
                    payPeriod.fromDate,
                    payPeriod.toDate,
                    userId,
                    matchedTS.processedAt
                  )
                }
              >
                Process/Unprocess
              </BsCheckSquareFill>
            )}
            <BsCalendar2Date
              className='icon-controller'
              onClick={() =>
                onView(payPeriod.fromDate, payPeriod.toDate, userId)
              }
            >
              View
            </BsCalendar2Date>
          </span>
        )}
      </td>
    </tr>
  );
};

export default PayPeriodUserRecord;
