import moment from "moment-timezone";

export function daysBetweenDates(date1, date2) {
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  const differenceInMilliseconds = date2 - date1;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return differenceInDays;
}

export function formatNYTime(dateString) {
  const parsedDate = moment.utc(dateString);
  return parsedDate.isValid() ? parsedDate.tz("America/New_York").format("MMM-DD HH:mm A") : "";
}

export function formatDate(dateObj) {
  const d = new Date(dateObj);
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
}

export function formatShortDate(dateObj) {
  const d = new Date(dateObj);
  let month = "" + (d.getUTCMonth() + 1),
    day = "" + d.getUTCDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day].join("-");
}

export function generateDateRange(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dateArray = [];

  while (start <= end) {
    dateArray.push(new Date(start).toISOString().split("T")[0]); // to get YYYY-MM-DD format
    start.setDate(start.getDate() + 1);
  }

  return dateArray;
}

export function getWeekdays(dates) {
  if (!Array.isArray(dates)) {
    throw new Error("Input must be an array");
  }

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const weekdays = dates.map((dateString) => {
    const [year, month, day] = dateString.split("-");
    const date = new Date(Date.UTC(year, month - 1, day));
    if (isNaN(date)) {
      throw new Error(`Invalid date string: ${dateString}`);
    }
    return days[date.getUTCDay()];
  });

  return weekdays;
}

export async function getHolidays(years, countryCode = "US") {
  try {
    const holidaysPromises = years.map((year) =>
      fetch(`https://date.nager.at/api/v3/PublicHolidays/${year}/${countryCode}`)
    );
    const holidaysResponses = await Promise.all(holidaysPromises);

    holidaysResponses.forEach((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok" + response.statusText);
      }
    });

    const holidaysArrays = await Promise.all(holidaysResponses.map((response) => response.json()));

    const allHolidays = [].concat(...holidaysArrays);
    return {
      holidayDates: allHolidays.map((holiday) => holiday.date),
      holidayNames: allHolidays.map((holiday) => holiday.localName),
    };
  } catch (error) {
    console.error("Fetching holiday data failed:", error);
    return [];
  }
}

export function isWeekend(_date) {
  const [year, month, day] = _date.split("-");
  const date = new Date(Date.UTC(year, month - 1, day));
  if (isNaN(date)) {
    throw new Error(`Invalid date: ${date}`);
  }

  const dayOfWeek = date.getUTCDay();
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return true;
  }

  return false;
}

export function isHoliday(_date, holidays) {
  const [year, month, day] = _date.split("-");
  const date = new Date(Date.UTC(year, month - 1, day));

  if (isNaN(date)) {
    throw new Error(`Invalid date: ${date}`);
  }

  const monthDay = date.toISOString().slice(0, 10);
  if (holidays.includes(monthDay)) {
    return true;
  }

  return false;
}

export function subtractTimes(startTimeStr, endTimeStr) {
  const [startHours, startMinutes] = startTimeStr.split(":").map(Number);
  const [endHours, endMinutes] = endTimeStr.split(":").map(Number);
  const startDate = new Date(0, 0, 0, startHours, startMinutes);
  const endDate = new Date(0, 0, 0, endHours, endMinutes);
  const diffMs = endDate - startDate;
  const diffMinutes = diffMs / (1000 * 60);
  const hours = diffMinutes / 60;
  return hours;
}

// input: {fromDate: "mm/dd/yyyy", toDate: "mm/dd/yyyy"}
export function addDaysToPeriod(dateObj, daysToAdd) {
  // Helper function to format a date as "mm-dd-yyyy"
  const formatDate = (date) => {
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // +1 because months are 0-based
    const year = date.getUTCFullYear();
    return `${month}-${day}-${year}`;
  };

  // Helper function to parse a date in the format "mm-dd-yyyy" or "mm/dd/yyyy" and add specified number of days
  const parseAndAddDays = (dateStr) => {
    // Replace any slashes with dashes to standardize the format
    const standardizedDateStr = dateStr.replace(/\//g, "-");
    const [month, day, year] = standardizedDateStr.split("-").map(Number);
    const date = new Date(Date.UTC(year, month - 1, day)); // -1 because months are 0-based
    date.setUTCDate(date.getUTCDate() + daysToAdd);
    return formatDate(date);
  };

  return {
    fromDate: parseAndAddDays(dateObj.fromDate),
    toDate: parseAndAddDays(dateObj.toDate),
  };
}

export function switchDateFormat(dateStr) {
  // Check if the format is "mm/dd/yyyy"
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
    return dateStr.replace(/\//g, "-");
  }

  // Check if the format is "mm-dd-yyyy"
  if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
    return dateStr.replace(/-/g, "/");
  }

  // If neither format matches, throw an error or return the original string (up to your requirements)
  throw new Error("Invalid date format provided.");
}

export function hasDatePassed(dateString) {
  // Parse the dateString into a Date object
  const [month, day, year] = dateString.split("-").map(Number);
  const inputDate = new Date(year, month - 1, day); // months are 0-indexed in JS

  // Get today's date and reset hours, minutes, seconds and milliseconds to 0
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Return true if inputDate is before today
  return inputDate < today;
}

export const convert24To12 = (hour) => {
  const hr = parseInt(hour, 10);
  if (hr === 0) return [12, "AM"];
  else if (hr === 12) return [12, "PM"];
  else if (hr > 12) return [hr - 12, "PM"];
  else return [hr, "AM"];
};

export const convert12To24 = (hour, period) => {
  let hr = parseInt(hour, 10);
  if (period === "PM" && hr !== 12) hr += 12;
  else if (period === "AM" && hr === 12) hr = 0;
  return hr.toString().padStart(2, "0");
};
