import "../resources/css/general.css";
import "./styles.css";

import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import axios from "axios";
import MessageBox from "../../components/MessageBox";
import { ERROR } from "../../constants/messageTypes";
import ContactItem from "../../components/ContactItem";

const ContactListPage = () => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get("/api/contact");
        const sortedList = response.data.slice().sort((a, b) => {
          if (a.teamId < b.teamId) return -1;
          if (a.teamId > b.teamId) return 1;
          if (a.firstName < b.firstName) return -1;
          if (a.firstName > b.firstName) return 1;
          return 0;
        });
        setContacts(sortedList);
        setFilteredContacts(sortedList);
      } catch (error) {
        displayMessage(ERROR, "Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  function searchContacts(keyword) {
    const formattedKeyword = keyword.replace(/-/g, "").toLowerCase();

    return contacts.filter((contact) => {
      // Check nested team object for teamName match
      if (contact.team && typeof contact.team.teamName === "string") {
        const formattedTeamName = contact.team.teamName.replace(/-/g, "").toLowerCase();
        if (formattedTeamName.includes(formattedKeyword)) {
          return true;
        }
      }

      // Check the other values of the contact object
      return Object.values(contact).some((value) => {
        if (typeof value === "string") {
          const formattedValue = value.replace(/-/g, "").toLowerCase();
          return formattedValue.includes(formattedKeyword);
        }
        return false;
      });
    });
  }

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleSearch = (e) => {
    const keyword = e.target.value;
    if (keyword.length === 0) {
      setFilteredContacts(contacts);
    } else {
      const filtered = searchContacts(keyword);
      setFilteredContacts(filtered);
    }
  };

  return (
    <div>
      <Header />
      <div className="contact-list-container">
        <h2>Directory</h2>
        <div>
          <div className="input-group">
            <label htmlFor="searchBox">Find People:</label>
            <input
              type="text"
              name="searchBox"
              id="searchBox"
              placeholder="Search for Name, Team, Position, Title, Phone ..."
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="item-list">
          {filteredContacts.map((contact) => (
            <ContactItem
              key={contact.id}
              contact={contact}
              currentUser={null}
              handleDelete={null}
              handleEdit={null}
            />
          ))}
        </div>

        {messageText && (
          <MessageBox type={messageType} message={messageText} onClear={() => setMessageText("")} />
        )}
      </div>
    </div>
  );
};

export default ContactListPage;
