export const HOME = "home";
export const HOME_LABEL = "Home";
export const REGISTER = "register";
export const REGISTER_LABEL = "Register";
export const LOGIN = "login";
export const LOGIN_LABEL = "Login";
export const LOGOUT = "logout";
export const LOGOUT_LABEL = "Logout";
export const PAY_CALENDAR = "pay-calendar";
export const PAY_CALENDAR_LABEL = "Pay Calendar";
export const DIRECTORY = "directory";
export const DIRECTORY_LABEL = "Directory";
export const TIMESHEET = "timesheet";
export const TIMESHEET_LABEL = "My Timesheet";
export const TEAM = "manage-team";
export const TEAM_LABEL = "Manage: Teams";
export const GROUP = "manage-group";
export const GROUP_LABEL = "Manage: Groups";
export const CONTACT = "manage-contact-list";
export const CONTACT_LABEL = "Manage: Contacts";
export const CONTACT_DETAIL = "manage-contact-detail";
export const CONTACT_DETAIL_LAEBL = "Manage: Contact Details";
export const PAY_PERIOD = "manage-pay-period";
export const PAY_PERIOD_LABEL = "Manage: Pay Periods";
export const DEFAULT_TIMESHEET_HEADER = "manage-default-timesheet-header";
export const DEFAULT_TIMESHEET_HEADER_LABEL = "Manage: Default Timesheet Header";
export const USER_TIMESHEET_HEADER = "manage-user-timesheet-header";
export const USER_TIMESHEET_HEADER_LABEL = "Common Tasks";
export const MY_ACCOUNT = "my-account";
export const MY_ACCOUNT_LABEL = "My Account";
export const MANAGE_USER = "manage-user";
export const MANAGE_USER_LABEL = "Manage: Users";
export const USER_MODIFY = "modify-user";
export const USER_MODIFY_LABEL = "Modify Users";
export const USER_TIMESHEET = "user-timesheet";
export const USER_TIMESHEET_LABEL = "Manage: User Timesheets";
export const UNDER_ME_USER_TIMESHET = "under-me-timesheeet";
export const UNDER_ME_USER_TIMESHET_LABEL = "Employeee Timesheets";
export const APPROVE_TIMESHEET = "approve-timehsheet";
export const APPROVE_TIMESHEET_LABEL = "Approve Timesheet";
export const PROCESS_TIMESHEET = "process-timesheet";
export const PROCESS_TIMESHEET_LABEL = "Proceess Timesheet";
export const RESET_PASSWORD = "reset-password";
export const RESET_PASSWORD_LABEL = "Reset Password";
export const RESET_PASSWORD_DONE = "reset";
export const CHANGE_MY_PASSWORD = "change-my-password";
export const CHANGE_MY_PASSWORD_LABEL = "Change Password";
export const HIERACHICAL_ORGANIZATION = "hierachical-organization";
export const HIERACHICAL_ORGANIZATION_LABEL = "Hierachical Organization";
export const USER_ROLE_ACCESS = "user-role-access";
export const USER_ROLE_ACCESS_LABEL = "Manage: User Access";
export const DIRECTORY_POLICY = "directory-app-policy";
