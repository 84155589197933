import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import { FaTrash, FaEdit } from 'react-icons/fa';

const GroupPage = () => {
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState({
    id: null,
    userGroupName: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');

  const loadGroups = useCallback(async () => {
    try {
      const response = await axios.get('/api/userGroup');
      setGroups(response.data);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  }, []);

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentGroup((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (currentGroup.userGroupName.length === 0) {
      displayMessage(ERROR, 'Group name cannot be blank.');
      return;
    }
    if (isEditing) {
      try {
        await axios.put(`/api/userGroup/${currentGroup.id}`, currentGroup);
        loadGroups();
        resetForm();
        displayMessage(SUCCESS, '1 group updated.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    } else {
      try {
        await axios.post('/api/userGroup', currentGroup);
        loadGroups();
        resetForm();
        displayMessage(SUCCESS, 'New group created.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    }
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const resetForm = () => {
    setCurrentGroup({ id: null, userGroupName: '' });
    setIsEditing(false);
  };

  const handleEdit = (group) => {
    setCurrentGroup(group);
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/userGroup/${id}`);
      loadGroups();
      displayMessage(SUCCESS, '1 group deleted.');
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>User Groups</h2>
        <div>
          <div className='input-group'>
            <label>Group Name</label>
            <input
              name='userGroupName'
              value={currentGroup.userGroupName}
              onChange={handleInputChange}
              placeholder='Name of user groups'
            />
          </div>
          <button onClick={handleSubmit}>
            {isEditing ? 'Update' : 'Create'}
          </button>
          {isEditing && <button onClick={resetForm}>Cancel</button>}
        </div>
        <div className='item-list'>
          {groups.map((group) => (
            <div
              className='g-row'
              key={group.id}
            >
              <div className='g-col-A'>{group.userGroupName}</div>
              <div className='g-col-B'>
                <FaEdit
                  className='icon-controller'
                  onClick={() => handleEdit(group)}
                >
                  Edit
                </FaEdit>
                <FaTrash
                  className='icon-controller'
                  onClick={() => handleDelete(group.id)}
                >
                  Delete
                </FaTrash>
              </div>
            </div>
          ))}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')} // Clear the message after it disappears
          />
        )}
      </div>
    </div>
  );
};

export default GroupPage;
