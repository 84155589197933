import "../resources/css/general.css";
import "./styles.css";

import Header from "../../components/Header";
import React, { useState, useEffect } from "react";
import axios from "axios";
import PayPeriodRecord from "../../components/PayPeriodRecord";
import MessageBox from "../../components/MessageBox";
import { SUCCESS, ERROR } from "../../constants/messageTypes";
import { formatDate } from "../../utils/DateUtils";

const PayPeriodPage = () => {
  const [fromDate, setFromDate] = useState("");
  const [payPeriods, setPayPeriods] = useState([]);
  const [toDate, setToDate] = useState("");
  const [payDate, setPayDate] = useState("");
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState("");

  const generatedPeriods = [];
  const failToAddPeriods = [];

  useEffect(() => {
    async function fetchPayPeriods() {
      try {
        const response = await axios.get("/api/payPeriod");
        setPayPeriods(response.data);
      } catch (error) {
        displayMessage(ERROR, "Error fetching pay periods:", error);
      }
    }

    fetchPayPeriods();
  }, []);

  const updateIsCurrent = (id) => {
    const updatedItems = payPeriods.map((item) =>
      item.id === id ? { ...item, isCurrent: true } : item
    );

    setPayPeriods(updatedItems);
  };

  const handleSetCurrent = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch("/api/payPeriod/");

      if (response.status === 200) {
        const updatedItem = response.data.data;
        updateIsCurrent(updatedItem.id);
        displayMessage(SUCCESS, "Current period is set to true successfully.");
      } else {
        displayMessage(ERROR, "Failed to update the current period.");
      }
    } catch (error) {
      displayMessage(ERROR, "An error occurred while updating the current period: " + error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (payPeriods.length > 0) {
      displayMessage(ERROR, "You can add only 1 origin date.");
      return;
    }

    if (!isValidDate(fromDate) || !isValidDate(toDate) || !isValidDate(payDate)) {
      displayMessage(ERROR, "Please enter valid dates.");
      return;
    }

    try {
      const isCurrent = false;
      const response = await axios.post("/api/payPeriod", {
        fromDate,
        toDate,
        payDate,
        isCurrent,
      });
      if (response.status === 201) {
        setPayPeriods([...payPeriods, response.data]);
        setFromDate("");
        setToDate("");
        setPayDate("");
        displayMessage(SUCCESS, "Origin Pay-Period-Date is added.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const handleCreateTimesheets = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch("/api/timesheet/createTimesheetsForUsers");
      if (response.status === 200) {
        displayMessage(SUCCESS, "Timesheets created.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const handlePayPeriodDeleted = (id) => {
    const updatedPayPeriods = payPeriods.filter((pp) => pp.id !== id);
    setPayPeriods(updatedPayPeriods);
  };

  const calculateDates = (dateString) => {
    const baseDate = new Date(dateString);
    const toDateObj = new Date(baseDate);
    toDateObj.setDate(baseDate.getDate() + 13);

    const payDateObj = new Date(toDateObj);
    payDateObj.setDate(toDateObj.getDate() + 13);

    return {
      fromDate: formatDate(baseDate),
      toDate: formatDate(toDateObj),
      payDate: formatDate(payDateObj),
    };
  };

  const handleDateBlur = (date) => {
    if (isValidDate(date)) {
      const {
        fromDate: formattedFromDate,
        toDate: calculatedToDate,
        payDate: calculatedPayDate,
      } = calculateDates(date);
      setFromDate(formattedFromDate);
      setToDate(calculatedToDate);
      setPayDate(calculatedPayDate);
    }
  };

  const isValidDate = (dateString) => {
    const timestamp = Date.parse(dateString);
    return !isNaN(timestamp);
  };

  const handleDeleteAll = async (e) => {
    e.preventDefault();

    if (payPeriods.length === 0) {
      displayMessage(ERROR, "There is no records to delete.");
      return;
    }

    try {
      const response = await axios.delete("/api/payPeriod/deleteAll");
      if (response.status === 200) {
        setPayPeriods([]);
        displayMessage(SUCCESS, "All records deleted.");
      } else {
        displayMessage(ERROR, "Something is wrong in server side.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const numberOfYears = 1;
  const handleAddYears = async (e) => {
    e.preventDefault();

    let currentFromDate = null;
    if (payPeriods.length > 0) {
      const lastDate = new Date(payPeriods[payPeriods.length - 1].toDate);
      lastDate.setDate(lastDate.getDate() + 1);
      currentFromDate = lastDate;
    } else {
      displayMessage(ERROR, "You must add an origin date.");
      return;
    }

    displayMessage(SUCCESS, "Please wait, generating ...");
    const numberOfPeriods = (52 / 2) * numberOfYears;
    for (let i = 0; i < numberOfPeriods; i++) {
      if (i !== 0) currentFromDate.setDate(currentFromDate.getDate() + 14);

      const { fromDate, toDate, payDate } = calculateDates(formatDate(currentFromDate));
      const tmpData = { fromDate, toDate, payDate, isCurrent: false };
      // Optional: Add to the backend
      try {
        const response = await axios.post("/api/payPeriod", tmpData);

        if (response.status === 201) generatedPeriods.push(response.data);
        else failToAddPeriods.push(tmpData);
      } catch (error) {
        displayMessage(ERROR, "Error adding pay period: " + error);
        failToAddPeriods.push(tmpData);
      }
    }

    displayMessage(SUCCESS, "New periods are generated.");
    setPayPeriods([...payPeriods, ...generatedPeriods]);
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleLockPassedTimesheets = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch("/api/timesheet/lockTimesheetsAfterDue");
      if (response.status === 200) {
        displayMessage(SUCCESS, "Timesheets locked.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const handleUnlockPassedTimesheets = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch("/api/timesheet/unlockAllCurrentTimesheets");
      if (response.status === 200) {
        displayMessage(SUCCESS, "Timesheets unlocked.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const handleApproveTimesheets = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch("/api/timesheet/approveAllCurrentTimesheets");
      if (response.status === 200) {
        displayMessage(SUCCESS, "Timesheets approved.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const handleUnapproveTimesheets = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch("/api/timesheet/unapproveAllCurrentTimesheets");
      if (response.status === 200) {
        displayMessage(SUCCESS, "Timesheets unapproved.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const handleProcessTimesheets = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch("/api/timesheet/processAllCurrentTimesheets");
      if (response.status === 200) {
        displayMessage(SUCCESS, "Timesheets processed.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  const handleUnprocessTimesheets = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch("/api/timesheet/unprocessAllCurrentTimesheets");
      if (response.status === 200) {
        displayMessage(SUCCESS, "Timesheets unprocessed.");
      }
    } catch (error) {
      displayMessage(ERROR, error);
    }
  };

  return (
    <div>
      <Header />
      <div className="payPeriod-controll-container">
        <button type="button" onClick={handleCreateTimesheets}>
          Create TS
        </button>
        <button type="button" onClick={handleLockPassedTimesheets}>
          Lock Passed TS
        </button>
        <button type="button" onClick={handleUnlockPassedTimesheets}>
          Unlock Current TS
        </button>
        <button type="button" onClick={handleApproveTimesheets}>
          Approve TS
        </button>
        <button type="button" onClick={handleUnapproveTimesheets}>
          Unapprove TS
        </button>
        <button type="button" onClick={handleProcessTimesheets}>
          Process TS
        </button>
        <button type="button" onClick={handleUnprocessTimesheets}>
          Unprocess TS
        </button>
      </div>
      <div className="payPeriod-container short-margin-top">
        <h2>PAY PERIODS</h2>
        {/* Form for adding new PayPeriod */}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>From</label>
            <input
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onBlur={(e) => handleDateBlur(e.target.value)}
              placeholder="xx/xx/xxxx"
            />
          </div>
          <div className="input-group">
            <label>To</label>
            <input value={toDate} readOnly placeholder="Auto-generated" />
          </div>
          <div className="input-group">
            <label>Pay Date</label>
            <input value={payDate} readOnly placeholder="Auto-generated" />
          </div>

          {messageText && (
            <MessageBox
              type={messageType}
              message={messageText}
              onClear={() => setMessageText("")} // Clear the message after it disappears
            />
          )}

          <button type="submit">Add</button>
          <button type="button" onClick={handleAddYears}>
            Generate {numberOfYears}-Year
          </button>
          <button type="button" onClick={handleDeleteAll}>
            Delete All
          </button>
          <button type="button" onClick={handleSetCurrent}>
            Set Current
          </button>
        </form>

        {/* Container displaying PayPeriods */}
        <div>
          <span className="from-to column-header">
            <span>Performance Period</span>
          </span>
          <span className="due-date column-header">
            <span>Due Date</span>
          </span>
          <span className="pay-date column-header">
            <span>Pay Date</span>
          </span>
          <span className="controller column-header">&nbsp;</span>
        </div>
        <div className="item-list">
          {payPeriods.map((payPeriod) => (
            <PayPeriodRecord
              key={payPeriod.id}
              payPeriod={payPeriod}
              onDeleted={handlePayPeriodDeleted}
              displayMessage={displayMessage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PayPeriodPage;
