import React, { useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { createAccess, updateAccess, deleteAccess } from "./accessSlice";

const AccessComponent = () => {
  const [currentItem, setCurrentItem] = useState("");
  const [editMode, setEditMode] = useState({ editing: false, id: null });
  const dispatch = useDispatch();
  const { accessList, loading, error } = useSelector((state) => state.access);
  const [filteredAccessList, setFilteredAccessList] = useState(accessList);

  const handleSave = () => {
    if (currentItem === "") {
      return;
    }

    if (editMode.editing) {
      dispatch(updateAccess({ id: editMode.id, AccessName: currentItem }));
      setEditMode({ editing: false, id: null });
      setCurrentItem("");
    } else {
      dispatch(createAccess({ AccessName: currentItem }));
      setCurrentItem("");
    }
  };

  const handleDelete = (item) => {
    dispatch(deleteAccess(item.id));
  };

  const handleEditMode = (item) => {
    setEditMode({ editing: true, id: item.id });
    setCurrentItem(item.AccessName);
  };

  const handleCancel = () => {
    setEditMode({ editing: false, id: null });
    setCurrentItem("");
  };

  const handleKeywordChange = (e) => {
    const keyword = e.target.value;
    setFilteredAccessList(
      accessList.filter((item) => item.AccessName.toLowerCase().includes(keyword.toLowerCase()))
    );
  };

  return (
    <div>
      <h2>Access Manager</h2>
      <div className="dual-block-container">
        <div className="dual-block-container-sub">
          <input
            type="text"
            placeholder="Create an Access Here ..."
            value={currentItem}
            onChange={(e) => setCurrentItem(e.target.value)}
          />
          <button className="btn-120" onClick={() => handleSave(null)}>
            {editMode.editing ? "Save" : "Create"}
          </button>
          {editMode.editing && (
            <button className="btn-120" onClick={() => handleCancel(null)}>
              Cancel
            </button>
          )}
        </div>
        <div className="dual-block-container-sub">
          <input
            type="text"
            name="txtKeyword"
            id="txtKeyword"
            placeholder="Access Search ..."
            onChange={handleKeywordChange}
          />
        </div>
      </div>

      <div className="item-list-wrapper large-wrapper">
        <div className="item-list">
          {filteredAccessList.map((item) => (
            <div className="g-row" key={item.id}>
              <div className="g-col-A">{item.AccessName}</div>
              <div className="g-col-B">
                <div className="g-svg-wrapper">
                  <FaEdit className="icon-controller" onClick={() => handleEditMode(item)}>
                    Edit
                  </FaEdit>
                  <FaTrash className="icon-controller" onClick={() => handleDelete(item)}>
                    Delete
                  </FaTrash>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccessComponent;
