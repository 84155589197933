import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { useAuth } from '../../contexts/authContext';
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi';

const UserTimesheetHeaderPage = () => {
  const { currentUser } = useAuth();
  const [headers, setHeaders] = useState([]);
  const { userId, email } = currentUser;
  const [currentHeader, setCurrentHeader] = useState({
    id: null,
    userId: currentUser.userId,
    headerName: '',
    order: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');

  const loadHeaders = useCallback(async () => {
    try {
      const response = await axios.get(`/api/userTimesheetHeader/${userId}`);
      const sortedHeaders = response.data.sort((a, b) => a.order - b.order);
      setHeaders(sortedHeaders);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  }, [userId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentHeader((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (currentHeader.headerName.length === 0) {
      displayMessage(ERROR, 'Header name cannot be blank.');
      return;
    }

    if (isEditing) {
      try {
        await axios.put(
          `/api/userTimesheetHeader/${currentHeader.id}`,
          currentHeader
        );
        loadHeaders();
        resetForm();
        displayMessage(SUCCESS, '1 header updated.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    } else {
      const isDuplicateOrder = headers.some(
        (header) => header.order === Number(currentHeader.order)
      );

      if (isDuplicateOrder) {
        displayMessage(ERROR, 'A header with the same order already exists.');
        return;
      }

      if (headers.length === 0) currentHeader.order = 1;
      else currentHeader.order = Math.max(...headers.map((x) => x.order)) + 1;

      try {
        await axios.post('/api/userTimesheetHeader', currentHeader);
        loadHeaders();
        resetForm();
        displayMessage(SUCCESS, 'New header created.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    }
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const resetForm = () => {
    setCurrentHeader({
      id: null,
      userId: userId,
      headerName: '',
      order: null,
    });
    setIsEditing(false);
  };

  const handleEdit = (header) => {
    setCurrentHeader(header);
    setIsEditing(true);
  };

  const handleGoDown = async (header) => {
    const currentOrder = header.order;
    const nextHeader = headers.find((h) => h.order === currentOrder + 1);

    if (!nextHeader) return; // If there's no next header to go down to.

    // Swap order values.
    header.order += 1;
    nextHeader.order -= 1;

    try {
      await axios.put(`/api/userTimesheetHeader/${header.id}`, header);
      await axios.put(`/api/userTimesheetHeader/${nextHeader.id}`, nextHeader);

      loadHeaders(); // Refresh headers to reflect the updated order.
      displayMessage(SUCCESS, 'Order updated.');
    } catch (error) {
      // On error, revert the order values.
      header.order -= 1;
      nextHeader.order += 1;
      displayMessage(ERROR, 'Error:', error);
    }
  };

  const handleGoUp = async (header) => {
    const currentOrder = header.order;
    const prevHeader = headers.find((h) => h.order === currentOrder - 1);

    if (!prevHeader) return; // If there's no previous header to go up to.

    // Swap order values.
    header.order -= 1;
    prevHeader.order += 1;

    try {
      await axios.put(`/api/userTimesheetHeader/${header.id}`, header);
      await axios.put(`/api/userTimesheetHeader/${prevHeader.id}`, prevHeader);

      loadHeaders(); // Refresh headers to reflect the updated order.
      displayMessage(SUCCESS, 'Order updated.');
    } catch (error) {
      // On error, revert the order values.
      header.order += 1;
      prevHeader.order -= 1;
      displayMessage(ERROR, 'Error:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/userTimesheetHeader/${id}`);
      loadHeaders();
      displayMessage(SUCCESS, '1 header deleted.');
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  };

  useEffect(() => {
    loadHeaders();
  }, [loadHeaders]);

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>Common Tasks</h2>
        <div className='uth-description'>
          These tasks will be created together with the timesheet by default.
        </div>
        <div>
          <div className='input-group'>
            <label>Username (readonly)</label>
            <input
              type='text'
              value={email}
              readOnly
            />
            <label>Task Name</label>
            <input
              name='headerName'
              value={currentHeader.headerName}
              onChange={handleInputChange}
              placeholder='Task Header'
            />
          </div>
          <button onClick={handleSubmit}>
            {isEditing ? 'Update' : 'Create'}
          </button>
          {isEditing && <button onClick={resetForm}>Cancel</button>}
        </div>
        <div className='item-list'>
          {headers.map((header) => (
            <div
              className='g-row'
              key={header.id}
            >
              <div className='g-col-A'>
                <span className='one-value-input'>{header.headerName}</span>
                <span className='one-value-input'>{header.order}</span>
              </div>
              <div className='g-col-B'>
                <BiDownArrowAlt
                  className='icon-controller'
                  onClick={() => handleGoDown(header)}
                />
                <BiUpArrowAlt
                  className='icon-controller'
                  onClick={() => handleGoUp(header)}
                />
                <FaEdit
                  className='icon-controller'
                  onClick={() => handleEdit(header)}
                />
                <FaTrash
                  className='icon-controller'
                  onClick={() => handleDelete(header.id)}
                />
              </div>
            </div>
          ))}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')}
          />
        )}
      </div>
    </div>
  );
};

export default UserTimesheetHeaderPage;
