import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import { useNavigate } from 'react-router-dom';
import { ADMIN, MANAGER, SUPER_MANAGER } from '../../constants/roles';
import { BsCalendar2Week } from 'react-icons/bs';
import { useAuth } from '../../contexts/authContext';
import { USER_TIMESHEET } from '../../constants/routeUrl';

const ProcessTimesheetPage = () => {
  const currentUser = useAuth().currentUser;
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');

  ////// FIX HERE MUST BE
  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`/api/user/approved-timesheet`);
      setUsers(response.data.data);
    } catch (error) {
      console.log(error);
      displayMessage(ERROR, 'Error:' + error.response.data.error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleOpenUserTimesheet = (user) => {
    navigate(`/${USER_TIMESHEET}/${user.id}`);
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>Process Timesheet</h2>
        <h5>Only users with timesheet approved but not processed shown</h5>
        <div className='item-list'>
          {users.map((user) => (
            <div
              className='g-row'
              key={user.id}
            >
              <span className='g-col-A'>
                <div
                  className={`us-name ${
                    user.groupId === ADMIN ? 'up-admin up-special' : ''
                  } ${
                    user.groupId === SUPER_MANAGER
                      ? 'up-super-manager up-special'
                      : ''
                  } ${
                    user.groupId === MANAGER ? 'up-manager up-special' : ''
                  } `}
                >
                  {user.firstName} {user.lastName}
                </div>
                <div className='us-email'>{user.email}</div>
              </span>
              <div className='g-col-B'>
                <div className='g-svg_wrapper'>
                  {[MANAGER, SUPER_MANAGER, ADMIN].includes(
                    currentUser.groupId
                  ) && (
                    <BsCalendar2Week
                      className='icon-controller'
                      onClick={() => handleOpenUserTimesheet(user)}
                    >
                      Edit
                    </BsCalendar2Week>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')} // Clear the message after it disappears
          />
        )}
      </div>
    </div>
  );
};

export default ProcessTimesheetPage;
