import '../resources/css/general.css';
import './styles.css';

import React, { useEffect } from 'react';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext';

function LogoutPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    // Send a request to the server to invalidate the session/token.
    axios
      .post('/api/user/logout')
      .then((response) => {
        if (response.data.success) {
          // Clear any client-side state.
          logout();

          // Redirect to a public page or the login page.
          navigate('/login');
        } else {
          // Handle any logout errors (e.g., show a message to the user).
          console.error('Failed to log out on the server.');
        }
      })
      .catch((error) => {
        console.error('An error occurred while logging out:', error);
      });
  }, [navigate, logout]);

  return (
    <div>
      <Header />
      <h2>Logging out...</h2>
    </div>
  );
}

export default LogoutPage;
