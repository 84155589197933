import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider } from "./contexts/authContext";
import ProtectedRoute from "./components/ProtectedRoute";

// Pages -----------------------------------------
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import TimesheetPage from "./pages/TimesheetPage";
import HomePage from "./pages/HomePage";
import PayPeriodPage from "./pages/PayPeriodPage";
import PayCalendarPage from "./pages/PayCalendarPage";
import TeamPage from "./pages/TeamPage";
import GroupPage from "./pages/GroupPage";
import ContactListPage from "./pages/ContactListPage";
import ContactListPublicPage from "./pages/ContactListPublicPage";
import ContactDetailPage from "./pages/ContactDetailPage";
import DefaultTimesheetHeaderPage from "./pages/DefaultTimesheetHeaderPage";
import UserTimesheetHeaderPage from "./pages/UserTimesheetHeaderPage";
import UsersPage from "./pages/UsersPage";
import UserModifyPage from "./pages/UserModifyPage";
import UserTimesheetPage from "./pages/UserTimesheetPage";
import UnderMeUserTimesheetPage from "./pages/UnderMeUserTimesheetPage";
import UserRoleAccessPage from "./pages/UserRoleAccessPage";

import {
  CONTACT,
  CONTACT_DETAIL,
  DEFAULT_TIMESHEET_HEADER,
  DIRECTORY,
  GROUP,
  LOGIN,
  LOGOUT,
  PAY_CALENDAR,
  PAY_PERIOD,
  TEAM,
  TIMESHEET,
  HOME,
  REGISTER,
  USER_TIMESHEET_HEADER,
  MY_ACCOUNT,
  MANAGE_USER,
  USER_MODIFY,
  USER_TIMESHEET,
  APPROVE_TIMESHEET,
  PROCESS_TIMESHEET,
  UNDER_ME_USER_TIMESHET,
  RESET_PASSWORD,
  RESET_PASSWORD_DONE,
  CHANGE_MY_PASSWORD,
  HIERACHICAL_ORGANIZATION,
  USER_ROLE_ACCESS,
  DIRECTORY_POLICY,
} from "./constants/routeUrl";
import MyAccountPage from "./pages/MyAccountPage";
import { EMPLOYEE, ADMIN, MANAGER, SUPER_MANAGER } from "./constants/roles";
import ApproveTimesheetPage from "./pages/ApproveTimesheetPage";
import ProcessTimesheetPage from "./pages/ProcessTimesheetPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ResetPasswordDonePage from "./pages/ResetPasswordDonePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import HierachicalOrganizationPage from "./pages/HierachicalOrganizationPage";
import DirectoryPolicyPage from "./pages/AppPolicy/DirectoryPolicyPage";

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to={"/" + HOME} replace />} />
            <Route path={"/" + HOME} element={<HomePage />} />
            <Route path={"/" + RESET_PASSWORD} element={<ResetPasswordPage />} />
            <Route path={"/" + RESET_PASSWORD_DONE} element={<ResetPasswordDonePage />} />
            <Route path={"/" + LOGIN} element={<LoginPage />} />
            <Route path={"/" + PAY_CALENDAR} element={<PayCalendarPage />} />
            <Route path={"/" + DIRECTORY_POLICY} element={<DirectoryPolicyPage />} />
            <Route
              path={"/" + TIMESHEET + "/:param_userId?/:param_fromDate?/:param_toDate?"}
              element={
                <ProtectedRoute roles={[EMPLOYEE, MANAGER, SUPER_MANAGER, ADMIN]}>
                  <TimesheetPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + CHANGE_MY_PASSWORD}
              element={
                <ProtectedRoute roles={[EMPLOYEE, MANAGER, SUPER_MANAGER, ADMIN]}>
                  <ChangePasswordPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + APPROVE_TIMESHEET}
              element={
                <ProtectedRoute roles={[MANAGER, SUPER_MANAGER, ADMIN]}>
                  <ApproveTimesheetPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + PROCESS_TIMESHEET}
              element={
                <ProtectedRoute roles={[SUPER_MANAGER, ADMIN]}>
                  <ProcessTimesheetPage />
                </ProtectedRoute>
              }
            />
            <Route path={"/" + LOGOUT} element={<LogoutPage />} />
            <Route
              path={"/" + DIRECTORY}
              element={
                <ProtectedRoute roles={[SUPER_MANAGER, ADMIN, EMPLOYEE, MANAGER]}>
                  <ContactListPublicPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + PAY_PERIOD}
              element={
                <ProtectedRoute roles={[ADMIN]}>
                  <PayPeriodPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + TEAM}
              element={
                <ProtectedRoute roles={[SUPER_MANAGER, ADMIN]}>
                  <TeamPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + GROUP}
              element={
                <ProtectedRoute roles={[ADMIN]}>
                  <GroupPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + CONTACT_DETAIL + "/:id?"}
              element={
                <ProtectedRoute roles={[SUPER_MANAGER, MANAGER, ADMIN]}>
                  <ContactDetailPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + USER_MODIFY + "/:id?"}
              element={
                <ProtectedRoute roles={[ADMIN, SUPER_MANAGER]}>
                  <UserModifyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + CONTACT}
              element={
                <ProtectedRoute>
                  <ContactListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + DEFAULT_TIMESHEET_HEADER}
              element={
                <ProtectedRoute roles={[ADMIN]}>
                  <DefaultTimesheetHeaderPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + USER_TIMESHEET_HEADER}
              element={
                <ProtectedRoute roles={[EMPLOYEE, MANAGER, SUPER_MANAGER, ADMIN]}>
                  <UserTimesheetHeaderPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + HIERACHICAL_ORGANIZATION}
              element={
                <ProtectedRoute roles={[EMPLOYEE, MANAGER, SUPER_MANAGER, ADMIN]}>
                  <HierachicalOrganizationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + MY_ACCOUNT}
              element={
                <ProtectedRoute roles={[EMPLOYEE, MANAGER, SUPER_MANAGER, ADMIN]}>
                  <MyAccountPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + MANAGE_USER}
              element={
                <ProtectedRoute roles={[ADMIN, SUPER_MANAGER]}>
                  <UsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + USER_ROLE_ACCESS}
              element={
                <ProtectedRoute roles={[ADMIN]}>
                  <UserRoleAccessPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + UNDER_ME_USER_TIMESHET}
              element={
                <ProtectedRoute roles={[ADMIN, SUPER_MANAGER, MANAGER]}>
                  <UnderMeUserTimesheetPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + REGISTER}
              element={
                <ProtectedRoute roles={[ADMIN, SUPER_MANAGER]}>
                  <RegisterPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/" + USER_TIMESHEET + "/:userId?"}
              element={
                <ProtectedRoute roles={[MANAGER, SUPER_MANAGER, ADMIN]}>
                  <UserTimesheetPage />
                </ProtectedRoute>
              }
            />
            {/* ... other routes */}
          </Routes>
        </Router>
      </AuthProvider>
    </Provider>
  );
}
export default App;
