import { useEffect, useState } from "react";

const ContactInfo = ({ label, value }) => {
  const [contactValue, setContactValue] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setContactValue(processValue(value));
  }, [value]);

  const processValue = (value) => {
    if (value === null || value === undefined || value === "") {
      return "N/A";
    } else if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    } else {
      if (value.length < 20) return value;
      else {
        return value.substring(0, 18) + "...";
      }
    }
  };

  const handleMouseEnter = () => {
    if (value === null || value === undefined || value === "") {
      setContactValue("N/A");
    } else {
      setContactValue(value);
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    setContactValue(processValue(value));
    setIsExpanded(false);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="contact-item-col">
      <div className="contact-item-label">{label}:</div>
      <div
        className={`contact-item-value ${isExpanded ? "civ-expanded" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleCopyToClipboard(contactValue)}
      >
        {contactValue}
      </div>
    </div>
  );
};

export default ContactInfo;
