import { useSelector, useDispatch } from "react-redux";
import { TbBrandCitymapper } from "react-icons/tb";
import { useEffect, useState } from "react";
import { AiOutlineDeleteRow } from "react-icons/ai";
import AccessModal from "./AccessModal";
import { createRoleAccess, deleteRoleAccess } from "../RoleAccess/roleAccessSlice";
import { GoTriangleRight } from "react-icons/go";

const RoleAccessComponent = () => {
  const dispatch = useDispatch();
  const { roleList } = useSelector((state) => state.role);
  const { accessList } = useSelector((state) => state.access);
  const { roleAccessList } = useSelector((state) => state.roleAccess);
  const [selectedRole, setSelectedRole] = useState(null);
  const [mappedAccess, setMappedAccess] = useState([]);
  const [accessModal, setAccessModal] = useState({
    visible: false,
    accessList: [],
    mappedAccess: [],
    role: null,
    onAdd: null,
  });

  useEffect(() => {
    if (accessList.length > 0) {
      setSelectedRole(roleList[0]);
    }
  }, [roleList]);

  useEffect(() => {
    if (selectedRole) {
      const _mappedAccess = accessList.filter((access) =>
        roleAccessList.some(
          (roleAccess) =>
            roleAccess && roleAccess.RoleId === selectedRole.id && roleAccess.AccessId === access.id
        )
      );
      setMappedAccess(_mappedAccess);
    }
  }, [selectedRole, roleAccessList]);

  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };

  const handleAddAccess = (roleId, accessId) => {
    dispatch(createRoleAccess({ RoleId: roleId, AccessId: accessId }));
  };

  const handleRemoveAccess = (accessId) => {
    dispatch(deleteRoleAccess({ RoleId: selectedRole.id, AccessId: accessId }));
  };

  return (
    <div>
      <h2>Role - Access Mapping</h2>
      <div className="binary-container">
        <div className="binary-sub-container">
          <div className="g-row-blank">
            <div className="g-col-A">
              <h3>Role</h3>
            </div>
            <div className="g-col-B"></div>
          </div>

          <div className="item-list">
            {roleList.map((item) => (
              <div
                className={`g-row ${selectedRole?.id === item.id ? `g-row-selected` : ``}`}
                key={item.id}
                onClick={() => handleSelectRole(item)}
              >
                <div className="g-col-A">
                  <GoTriangleRight /> {item.RoleName}
                </div>
                <div className="g-col-B">
                  <div className="g-svg-wrapper">
                    <TbBrandCitymapper className="icon-controller">Map</TbBrandCitymapper>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="binary-sub-container">
          <div className="g-row-blank">
            <div className="g-col-A">
              <h3>Access</h3>
            </div>
            <div className="g-col-B">
              <button
                className="btn-120"
                onClick={() =>
                  setAccessModal({
                    ...accessModal,
                    visible: true,
                  })
                }
              >
                Map
              </button>
            </div>
          </div>

          <div className="item-list">
            {mappedAccess.map((item) => (
              <div className="g-row" key={item.id}>
                <div className="g-col-A">{item.AccessName}</div>
                <div className="g-col-B">
                  <div className="g-svg-wrapper">
                    <AiOutlineDeleteRow
                      className="icon-controller"
                      onClick={() => handleRemoveAccess(item.id)}
                    >
                      Remove
                    </AiOutlineDeleteRow>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AccessModal
        visible={accessModal.visible}
        mappedAccess={mappedAccess}
        accessList={accessList}
        role={selectedRole}
        onAdd={handleAddAccess}
        onClose={() => setAccessModal({ ...accessModal, visible: false })}
      />
    </div>
  );
};

export default RoleAccessComponent;
