import "../resources/css/general.css";
import "./styles.css";

import { useState } from "react";
import Header from "../../components/Header";
import TimesheetTable from "../../components/TimesheetTable";
import { useAuth } from "../../contexts/authContext";
import { useEffect } from "react";
import axios from "axios";
import { addDaysToPeriod, hasDatePassed, switchDateFormat } from "../../utils/DateUtils";
import MessageBox from "../../components/MessageBox";
import { SUCCESS, ERROR } from "../../constants/messageTypes";
import { TiPlus } from "react-icons/ti";
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiFillSave } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { ADMIN, SUPER_MANAGER, MANAGER } from "../../constants/roles";
import { BsLock, BsCheckSquare, BsCheckSquareFill } from "react-icons/bs";

function TimesheetPage() {
  const { currentUser } = useAuth();
  const { param_userId, param_fromDate, param_toDate } = useParams();
  const [managerMod, setManagerMod] = useState(false);
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(1);
  const [payPeriod, setPayPeriod] = useState(null);
  const [timesheet, setTimesheet] = useState(null);
  const [error, setError] = useState(null);
  const [newTaskName, setNewTaskName] = useState("");
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [timesheetUser, setTimesheetUser] = useState(null);

  const get3PayPeriods = (midPeriod) => {
    const nextPeriod = addDaysToPeriod(midPeriod, 14);
    const prevPeriod = addDaysToPeriod(midPeriod, -14);
    return [prevPeriod, midPeriod, nextPeriod];
  };

  useEffect(() => {
    const _managerMod = param_userId && param_fromDate && param_toDate ? true : false;
    setManagerMod(_managerMod);
    const initSetup = async () => {
      try {
        let currentPeriod = null;
        if (!_managerMod) {
          const response = await axios.get("/api/payPeriod/current");
          currentPeriod = {
            fromDate: switchDateFormat(response.data.fromDate),
            toDate: switchDateFormat(response.data.toDate),
          };
        } else {
          currentPeriod = {
            fromDate: param_fromDate,
            toDate: param_toDate,
          };
        }
        if (!_managerMod) {
          const duePeriod = addDaysToPeriod(currentPeriod, 9);
          if (hasDatePassed(duePeriod.toDate)) currentPeriod = addDaysToPeriod(currentPeriod, 14);
        }
        const _3periods = get3PayPeriods(currentPeriod);
        setPeriods(_3periods);

        let _tmp = null;
        if (!_managerMod) {
          _tmp = await fetchTimesheet(
            currentPeriod.fromDate,
            currentPeriod.toDate,
            currentUser.userId
          );
        } else {
          _tmp = await fetchTimesheet(currentPeriod.fromDate, currentPeriod.toDate, param_userId);
        }

        if (_tmp) {
          const initIndex = 1;
          setTimesheet((prev) => _tmp);
          setPayPeriod((prev) => _3periods[initIndex]);
          setSelectedPeriodIndex((prev) => initIndex);
        }
      } catch (err) {
        setError("Error fetching pay period:" + err);
      }
    };

    initSetup();
  }, [param_userId, param_fromDate, param_toDate]);

  useEffect(() => {
    const fetchTimesheetUser = async (userId) => {
      const response = await axios.get(`/api/user/${userId}`);

      setTimesheetUser((prev) => response.data);
    };

    if (timesheet) {
      fetchTimesheetUser(timesheet.userId);
    }
  }, [timesheet]);

  const fetchTimesheet = async (fromDate, toDate, userId) => {
    try {
      fromDate = fromDate.replace(/\//g, "-");
      toDate = toDate.replace(/\//g, "-");
      const response = await axios.get(`/api/timesheet/${fromDate}/${toDate}/${userId}`);
      //return response.data;
      // change it to fetch from database
      const data = {
        ...response.data,
      };
      if (!data?.selfBreaks) {
        data.selfBreaks = [[], [], [], [], [], [], [], [], [], [], [], [], [], []];
      }
      return data;
    } catch (err) {
      displayMessage(ERROR, "Error fetching timesheet.");
    } finally {
      setLoading(false);
    }
  };

  if (!currentUser) {
    return <div>Loading user data...</div>;
  }

  if (!payPeriod || !timesheet) {
    return <div>No data.</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const maxOrderUnder900 = Math.max(
    ...timesheet.data.filter((task) => task.order < 900).map((task) => task.order)
  );

  const handleAddTask = () => {
    if (newTaskName.trim() !== "" && timesheet) {
      setTimesheet((prevData) => {
        // Deep copy of the previous data to avoid direct mutation
        const newData = JSON.parse(JSON.stringify(prevData));

        const newTask = {
          headerName: newTaskName,
          order: maxOrderUnder900 + 1,
          data: Array(timesheet.data[0].data.length).fill("00:00-00:00"), // assuming all rows have the same number of columns
        };

        const insertIndex = newData.data.findIndex((task) => task.order > newTask.order);

        if (insertIndex === -1) {
          newData.data.push(newTask);
        } else {
          newData.data.splice(insertIndex, 0, newTask);
        }

        return newData;
      });
      setNewTaskName("");
    }
  };

  const handleDeleteRow = (rowIndex) => {
    setTimesheet((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData));
      newData.data.splice(rowIndex, 1);
      return newData;
    });
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleSave = async () => {
    if (timesheet.isCompleted) {
      displayMessage(ERROR, "This timesheet is already passed due or locked. Cannot make changes.");
      return;
    }
    try {
      const fromDateFormatted = payPeriod.fromDate;
      const toDateFormatted = payPeriod.toDate;
      const userId = managerMod ? param_userId : currentUser.userId;

      const response = await axios.put(
        `/api/timesheet/${fromDateFormatted}/${toDateFormatted}/${userId}`,
        timesheet // Assuming the backend expects the whole timesheet as the request body
      );

      if (response.status === 200) {
        console.log("Timesheet saved successfully!", response.data);
        displayMessage(SUCCESS, "Timesheet saved.");
      } else {
        console.error("Failed to save timesheet:", response.data);
        displayMessage(ERROR, "Error saving timesheet", response.data);
      }
    } catch (err) {
      console.error("Error saving timesheet:", err);
      displayMessage(ERROR, "Error while saving timesheet", err);
    }
  };

  const handleGoPrev = async () => {
    if (selectedPeriodIndex > 0) {
      let newIndex = selectedPeriodIndex - 1;
      const tmpPayPeriod = periods[newIndex];
      const foundTimesheet = await fetchTimesheet(
        tmpPayPeriod.fromDate,
        tmpPayPeriod.toDate,
        currentUser.userId
      );
      if (foundTimesheet) {
        setTimesheet((prev) => foundTimesheet);
        setPayPeriod((prev) => tmpPayPeriod);
        setSelectedPeriodIndex((prev) => newIndex);
        return;
      } else {
        displayMessage(ERROR, "Previous period timesheet is not available.");
        return;
      }
    } else {
      displayMessage(ERROR, "This is the last timesheet you can move backward.");
      return;
    }
  };

  const handleGoNext = async () => {
    if (selectedPeriodIndex < periods.length - 1) {
      let newIndex = selectedPeriodIndex + 1;
      const tmpPayPeriod = periods[newIndex];
      const foundTimesheet = await fetchTimesheet(
        tmpPayPeriod.fromDate,
        tmpPayPeriod.toDate,
        currentUser.userId
      );
      if (foundTimesheet) {
        setTimesheet((prev) => foundTimesheet);
        setPayPeriod((prev) => tmpPayPeriod);
        setSelectedPeriodIndex((prev) => newIndex);
        return;
      } else {
        displayMessage(ERROR, "Next period timesheet is not available.");
        return;
      }
    } else {
      displayMessage(ERROR, "This is the last timesheet you can move forward.");
      return;
    }
  };

  const handleTimesheetUpdate = (updatedTimesheet) => {
    setTimesheet(updatedTimesheet);
  };

  const handleLock = async () => {
    const from = payPeriod?.fromDate.replace(/\//g, "-");
    const to = payPeriod?.toDate.replace(/\//g, "-");
    const uId = timesheet?.userId;

    if (!from || !to || !uId) return;

    // if unlocked => lock
    if (!timesheet.isCompleted) {
      try {
        const response = await axios.patch(`/api/timesheet/lockTimesheet/${from}/${to}/${uId}`);
        if (response.data.success) {
          setTimesheet((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.isCompleted = true;
            return newData;
          });
          displayMessage(SUCCESS, "Timesheet locked.");
        } else {
          displayMessage(ERROR, "Not success: " + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, "Internal error: " + error.response.data.error);
      }
    }
    // if locked => unlock
    else {
      try {
        const response = await axios.patch(`/api/timesheet/unlockTimesheet/${from}/${to}/${uId}`);
        if (response.data.success) {
          setTimesheet((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.isCompleted = false;
            return newData;
          });
          displayMessage(SUCCESS, "Timesheet is unlocked.");
        } else {
          displayMessage(ERROR, "Not success: " + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, "Internal error: " + error.response.data.error);
      }
    }
  };

  const handleApprove = async () => {
    const from = payPeriod?.fromDate.replace(/\//g, "-");
    const to = payPeriod?.toDate.replace(/\//g, "-");
    const uId = timesheet?.userId;

    if (!from || !to || !uId) return;

    // if unapproved => approve
    if (!timesheet.approvedAt) {
      try {
        const response = await axios.put(
          `/api/timesheet/approveTimesheet/${from}/${to}/${uId}`,
          currentUser
        );
        if (response.data.success) {
          setTimesheet((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.approvedBy = currentUser.id;
            newData.approvedAt = new Date();
            return newData;
          });
          displayMessage(SUCCESS, "Timesheet is approved.");
        } else {
          displayMessage(ERROR, "Not success: " + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, "Internal error: " + error.response.data.error);
      }
    }
    // if approved => unapprove
    else {
      try {
        const response = await axios.patch(
          `/api/timesheet/unapproveTimesheet/${from}/${to}/${uId}`
        );
        if (response.data.success) {
          setTimesheet((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.approvedBy = null;
            newData.approvedAt = null;
            return newData;
          });
          displayMessage(SUCCESS, "Timesheet is unapproved.");
        } else {
          displayMessage(ERROR, "Not success: " + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, "Internal error: " + error.response.data.error);
      }
    }
  };

  const handleProcess = async () => {
    const from = payPeriod?.fromDate.replace(/\//g, "-");
    const to = payPeriod?.toDate.replace(/\//g, "-");
    const uId = timesheet?.userId;

    if (!from || !to || !uId) return;

    // if unprocessed => process
    if (!timesheet.processedAt) {
      try {
        const response = await axios.put(
          `/api/timesheet/processTimesheet/${from}/${to}/${uId}`,
          currentUser
        );
        if (response.data.success) {
          setTimesheet((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.processedBy = currentUser.id;
            newData.processedAt = new Date();
            return newData;
          });
          displayMessage(SUCCESS, "Timesheet is processed.");
        } else {
          displayMessage(ERROR, "Not success: " + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, "Internal error: " + error.response.data.error);
      }
    }
    // if processed => unprocess
    else {
      try {
        const response = await axios.patch(
          `/api/timesheet/unprocessTimesheet/${from}/${to}/${uId}`
        );
        if (response.data.success) {
          setTimesheet((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.processedBy = null;
            newData.processedAt = null;
            return newData;
          });
          displayMessage(SUCCESS, "Timesheet is unprocessed.");
        } else {
          displayMessage(ERROR, "Not success: " + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, "Internal error: " + error.response.data.error);
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="timesheet-container">
        <div className="ts-full-col">
          <h2 className="ts-h2">
            {managerMod ? `${timesheetUser?.firstName} ${timesheetUser?.lastName}` : "MY TIMESHEET"}
          </h2>

          <div className="ts-label-block-1">
            <span className="ts-current-period">
              {payPeriod.fromDate + ` to ` + payPeriod.toDate}
            </span>
          </div>

          {managerMod && (
            <div className="ts-float-right">
              <h2 className="ts-h2 ts-manager-mod">SUPERVISOR MODE</h2>
              <div className="ts-controllers">
                <span>
                  {[MANAGER, SUPER_MANAGER, ADMIN].includes(currentUser.groupId) && (
                    <BsLock
                      className="icon-controller"
                      onClick={() =>
                        handleLock(payPeriod.fromDate, payPeriod.toDate, timesheet.userId)
                      }
                    >
                      Lock/Unlock
                    </BsLock>
                  )}
                  {[MANAGER, SUPER_MANAGER, ADMIN].includes(currentUser.groupId) && (
                    <BsCheckSquare
                      className="icon-controller"
                      onClick={() =>
                        handleApprove(payPeriod.fromDate, payPeriod.toDate, timesheet.userId)
                      }
                    >
                      Approve/Disapprove
                    </BsCheckSquare>
                  )}
                  {[SUPER_MANAGER, ADMIN].includes(currentUser.groupId) && (
                    <BsCheckSquareFill
                      className="icon-controller"
                      onClick={() =>
                        handleProcess(payPeriod.fromDate, payPeriod.toDate, timesheet.userId)
                      }
                    >
                      Process/Unprocess
                    </BsCheckSquareFill>
                  )}
                </span>
              </div>
            </div>
          )}

          <div className="clr"></div>
        </div>

        <div className="ts-control-col">
          <div className="input-group">
            <input
              className="ts-taskName-input"
              type="text"
              name="taskName"
              id="taskName"
              placeholder="Task Name/Description"
              value={newTaskName}
              onChange={(e) => setNewTaskName(e.target.value)}
            />
            <button className="ts-button" onClick={handleAddTask}>
              <TiPlus className="button-img" size={24} /> Add Task
            </button>
          </div>
        </div>
        <div className="ts-control-col ts-margin-2">
          <div className="input-group">
            {!managerMod && (
              <button className="ts-button ts-btn-margin" onClick={handleGoPrev}>
                <AiOutlineCaretLeft className="button-img" size={24} />
                Prev Period
              </button>
            )}
            {!managerMod && (
              <button className="ts-button ts-btn-margin" onClick={handleGoNext}>
                <AiOutlineCaretRight className="button-img" size={24} />
                Next Period
              </button>
            )}
            <button className="ts-button ts-btn-margin" onClick={handleSave}>
              <AiFillSave className="button-img" size={24} />
              Save Timesheet
            </button>
          </div>

          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>
      <TimesheetTable
        timesheet={timesheet}
        timesheetUser={timesheetUser}
        onDeleteRow={handleDeleteRow}
        onNotifyError={displayMessage}
        onTimesheetUpdate={handleTimesheetUpdate}
        currentUser={currentUser}
      />
      {messageText && (
        <MessageBox
          type={messageType}
          message={messageText}
          onClear={() => setMessageText("")} // Clear the message after it disappears
        />
      )}
    </div>
  );
}

export default TimesheetPage;
