import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { SUCCESS, ERROR } from '../../constants/messageTypes';
import { FaTrash, FaEdit } from 'react-icons/fa';

const TeamPage = () => {
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState({ id: null, teamName: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');

  const loadTeams = useCallback(async () => {
    try {
      const response = await axios.get('/api/team');
      setTeams(response.data);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  }, []);

  useEffect(() => {
    loadTeams();
  }, [loadTeams]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentTeam((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (currentTeam.teamName.length === 0) {
      displayMessage(ERROR, 'Team name cannot be blank.');
      return;
    }
    if (isEditing) {
      try {
        await axios.put(`/api/team/${currentTeam.id}`, currentTeam);
        loadTeams();
        resetForm();
        displayMessage(SUCCESS, '1 team updated.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    } else {
      try {
        await axios.post('/api/team', currentTeam);
        loadTeams();
        resetForm();
        displayMessage(SUCCESS, 'New team created.');
      } catch (error) {
        displayMessage(ERROR, 'Error:', error);
      }
    }
  };

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const resetForm = () => {
    setCurrentTeam({ id: null, teamName: '' });
    setIsEditing(false);
  };

  const handleEdit = (team) => {
    setCurrentTeam(team);
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/team/${id}`);
      loadTeams();
      displayMessage(SUCCESS, '1 team deleted.');
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>Teams</h2>
        <div>
          <div className='input-group'>
            <label>Team Name</label>
            <input
              name='teamName'
              value={currentTeam.teamName}
              onChange={handleInputChange}
              placeholder='Name of DFD Team'
            />
          </div>
          <button onClick={handleSubmit}>
            {isEditing ? 'Update' : 'Create'}
          </button>
          {isEditing && <button onClick={resetForm}>Cancel</button>}
        </div>
        <div className='item-list'>
          {teams.map((team) => (
            <div
              className='g-row'
              key={team.id}
            >
              <div className='g-col-A'>{team.teamName}</div>
              <div className='g-col-B'>
                <div className='g-svg-wrapper'>
                  <FaEdit
                    className='icon-controller'
                    onClick={() => handleEdit(team)}
                  >
                    Edit
                  </FaEdit>
                  <FaTrash
                    className='icon-controller'
                    onClick={() => handleDelete(team.id)}
                  >
                    Delete
                  </FaTrash>
                </div>
              </div>
            </div>
          ))}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')}
          />
        )}
      </div>
    </div>
  );
};

export default TeamPage;
