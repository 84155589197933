import React from "react";
import "./styles.css";
import { BiHomeCircle, BiLogInCircle, BiLogOutCircle } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { TbHierarchy3 } from "react-icons/tb";
import { LuContact2 } from "react-icons/lu";
import { IoCalendarNumberSharp } from "react-icons/io5";
import { MdContactEmergency } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";
import { GrUserManager, GrUserAdmin, GrUserWorker, GrGroup } from "react-icons/gr";
import { ImMenu } from "react-icons/im";
import { RiMenuAddFill } from "react-icons/ri";
import { CgUserList } from "react-icons/cg";
import { TbCalendarTime } from "react-icons/tb";
import { BsCalendar3, BsCalendarCheck, BsCalendar2CheckFill, BsLock } from "react-icons/bs";
import { IoAccessibilitySharp } from "react-icons/io5";
import { useState } from "react";

import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import {
  CONTACT_LABEL,
  DEFAULT_TIMESHEET_HEADER,
  DEFAULT_TIMESHEET_HEADER_LABEL,
  DIRECTORY,
  GROUP_LABEL,
  LOGIN_LABEL,
  LOGOUT_LABEL,
  PAY_PERIOD,
  TEAM,
  TEAM_LABEL,
  TIMESHEET_LABEL,
  PAY_CALENDAR,
  DIRECTORY_LABEL,
  TIMESHEET,
  PAY_PERIOD_LABEL,
  GROUP,
  CONTACT,
  LOGOUT,
  LOGIN,
  PAY_CALENDAR_LABEL,
  USER_TIMESHEET_HEADER,
  USER_TIMESHEET_HEADER_LABEL,
  MY_ACCOUNT,
  MY_ACCOUNT_LABEL,
  MANAGE_USER,
  MANAGE_USER_LABEL,
  APPROVE_TIMESHEET,
  APPROVE_TIMESHEET_LABEL,
  PROCESS_TIMESHEET_LABEL,
  PROCESS_TIMESHEET,
  UNDER_ME_USER_TIMESHET,
  UNDER_ME_USER_TIMESHET_LABEL,
  CHANGE_MY_PASSWORD,
  CHANGE_MY_PASSWORD_LABEL,
  HIERACHICAL_ORGANIZATION,
  HIERACHICAL_ORGANIZATION_LABEL,
  USER_ROLE_ACCESS,
  USER_ROLE_ACCESS_LABEL,
} from "../../constants/routeUrl";
import { MANAGER, SUPER_MANAGER, ADMIN } from "../../constants/roles";

function Header() {
  const { currentUser } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <header className="header-container no-print">
      <div className="logo-container">
        {/* You can replace the div below with an <img> tag if you have a logo image */}
        <div className="logo">Dynamic Facility Designs</div>
      </div>
      <nav className="navbar">
        <ul>
          {/* Everyone can see */}
          <li>
            <BiHomeCircle size={24} />
            <Link to="/">Home</Link>
          </li>

          {/* Member cannot see */}
          {!currentUser && (
            <li>
              <BiLogInCircle size={24} />
              <Link to={"/" + LOGIN}>{LOGIN_LABEL}</Link>
            </li>
          )}

          {/* Member can see */}
          {currentUser && (
            <>
              <li
                className="dropdown"
                onMouseEnter={() => setShowDropdown(true)}
                onMouseLeave={() => setShowDropdown(false)}
              >
                <CgProfile size={24} />
                <Link to={"/" + MY_ACCOUNT}>{MY_ACCOUNT_LABEL}</Link>
                {showDropdown && (
                  <div className="dropdown-content">
                    <ul>
                      <li>
                        <BsCalendar3 size={24} />
                        <Link to={"/" + TIMESHEET}>{TIMESHEET_LABEL}</Link>
                      </li>
                      <li>
                        <RiMenuAddFill size={24} />
                        <Link to={"/" + USER_TIMESHEET_HEADER}>{USER_TIMESHEET_HEADER_LABEL}</Link>
                      </li>
                      <li>
                        <BsLock size={24} />
                        <Link to={"/" + CHANGE_MY_PASSWORD}>{CHANGE_MY_PASSWORD_LABEL}</Link>
                      </li>
                      <li>
                        <MdContactEmergency size={24} />
                        <Link to={"/" + DIRECTORY}>{DIRECTORY_LABEL}</Link>
                      </li>
                      <li>
                        <TbHierarchy3 size={24} />
                        <Link to={"/" + HIERACHICAL_ORGANIZATION}>
                          {HIERACHICAL_ORGANIZATION_LABEL}
                        </Link>
                      </li>
                      <li>
                        <IoCalendarNumberSharp size={24} />
                        <Link to={"/" + PAY_CALENDAR}>{PAY_CALENDAR_LABEL}</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </li>

              {/* Manager can see */}
              {(currentUser.groupId === MANAGER ||
                currentUser.groupId === SUPER_MANAGER ||
                currentUser.groupId === ADMIN) && (
                <>
                  <li
                    className="dropdown"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                  >
                    <GrUserWorker size={24} />
                    <div className="nav-item">Manager</div>
                    {showDropdown && (
                      <div className="dropdown-content">
                        <ul>
                          <li>
                            <BsCalendarCheck size={24} />
                            <Link to={"/" + APPROVE_TIMESHEET}>{APPROVE_TIMESHEET_LABEL}</Link>
                          </li>
                          <li>
                            <LuContact2 size={24} />
                            <Link to={"/" + CONTACT}>{CONTACT_LABEL}</Link>
                          </li>
                          <li>
                            <CgUserList size={24} />
                            <Link to={"/" + UNDER_ME_USER_TIMESHET}>
                              {UNDER_ME_USER_TIMESHET_LABEL}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </>
              )}

              {/* Super Manager can see */}
              {(currentUser.groupId === SUPER_MANAGER || currentUser.groupId === ADMIN) && (
                <>
                  <li
                    className="dropdown"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                  >
                    <GrUserManager size={24} />
                    <div className="nav-item">Super Manager</div>
                    {showDropdown && (
                      <div className="dropdown-content">
                        <ul>
                          <li>
                            <BsCalendar2CheckFill size={24} />
                            <Link to={`/${PROCESS_TIMESHEET}`}>{PROCESS_TIMESHEET_LABEL}</Link>
                          </li>
                          <li>
                            <AiOutlineTeam size={24} />
                            <Link to={"/" + TEAM}>{TEAM_LABEL}</Link>
                          </li>
                          <li>
                            <CgUserList size={24} />
                            <Link to={"/" + MANAGE_USER}>{MANAGE_USER_LABEL}</Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </>
              )}

              {/* Admin can see */}
              {currentUser.groupId === ADMIN && (
                <>
                  <li
                    className="dropdown"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                  >
                    <GrUserAdmin size={24} />
                    <div className="nav-item">Administrator</div>
                    {showDropdown && (
                      <div className="dropdown-content">
                        <ul>
                          <li>
                            <TbCalendarTime size={24} />
                            <Link to={"/" + PAY_PERIOD}>{PAY_PERIOD_LABEL}</Link>
                          </li>
                          <li>
                            <GrGroup size={24} />
                            <Link to={"/" + GROUP}>{GROUP_LABEL}</Link>
                          </li>
                          <li>
                            <ImMenu size={24} />
                            <Link to={"/" + DEFAULT_TIMESHEET_HEADER}>
                              {DEFAULT_TIMESHEET_HEADER_LABEL}
                            </Link>
                          </li>
                          <li>
                            <IoAccessibilitySharp size={24} />
                            <Link to={"/" + USER_ROLE_ACCESS}>{USER_ROLE_ACCESS_LABEL}</Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </>
              )}
              <li>
                <BiLogOutCircle size={24} />
                <Link to={"/" + LOGOUT}>{currentUser?.firstName + ", " + LOGOUT_LABEL}</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
      <div className="credit-container">
        DMS - Developed & maintained by Louis Tran. For inquiries or support, please contact
        louis@dfdva.com.
      </div>
    </header>
  );
}

export default Header;
