import '../resources/css/general.css';
import './styles.css';

import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import PayPeriodUserRecord from '../../components/PayPeriodUserRecord';
import { useParams } from 'react-router-dom';
import MessageBox from '../../components/MessageBox';
import { ERROR, SUCCESS } from '../../constants/messageTypes';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { TIMESHEET } from '../../constants/routeUrl';
import { switchDateFormat } from '../../utils/DateUtils';

function UserTimesheetPage() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { userId } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [payPeriods, setPayPeriods] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [userTSData, setUserTSData] = useState([]);

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  useEffect(() => {
    const fetchUserTimesheet = async () => {
      try {
        const response = await axios.get(`/api/timesheet/${userId}`);
        const _tsData = response.data;
        setUserTSData(_tsData);
      } catch (error) {
        displayMessage(ERROR, 'Error:' + error.response.data.error);
      }
    };

    fetchUserTimesheet();
  }, [userId]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`/api/user/${userId}`);
        const _userInfo = { ...response.data, password: '' };
        setUserInfo(_userInfo);
      } catch (error) {
        displayMessage(ERROR, 'Error:' + error.response.data.error);
      }
    };

    fetchUserInfo();
  }, [userId]);

  useEffect(() => {
    async function fetchPayPeriods() {
      try {
        const response = await axios.get('/api/payPeriod');
        setPayPeriods(response.data);
      } catch (error) {
        displayMessage(ERROR, 'Error:' + error.response.data.error);
      }
    }

    fetchPayPeriods();
  }, []);

  if (!userInfo) {
    return <div>No Data</div>;
  }

  const handleCreate = async (from, to, uId) => {
    from = switchDateFormat(from);
    to = switchDateFormat(to);

    if (!from || !to || !uId) return;

    try {
      const response = await axios.post(
        `/api/timesheet/createTimesheet/${from}/${to}/${uId}`
      );
      if (response.data.success) {
        console.log(response.data);
        setUserTSData((prevData) => {
          const newData = JSON.parse(JSON.stringify(prevData));
          newData.push(response.data.data);
          return newData;
        });
        displayMessage(SUCCESS, 'Timesheet added.');
      } else {
        displayMessage(ERROR, 'Not success: ' + response.data.error);
      }
    } catch (error) {
      displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
    }
  };

  const handleDelete = async (from, to, uId) => {
    from = switchDateFormat(from);
    to = switchDateFormat(to);

    if (!from || !to || !uId) return;

    try {
      const response = await axios.delete(
        `/api/timesheet/${from}/${to}/${uId}`,
        currentUser
      );
      if (response.data.success) {
        setUserTSData((prevData) => {
          const newData = JSON.parse(JSON.stringify(prevData));
          return newData.filter(
            (item) =>
              !(
                item.fromDate === switchDateFormat(from) &&
                item.toDate === switchDateFormat(to) &&
                item.userId === Number(uId)
              )
          );
        });
        displayMessage(SUCCESS, 'Timesheet deleted.');
      } else {
        displayMessage(ERROR, 'Not success: ' + response.data.error);
      }
    } catch (error) {
      displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
    }
  };

  const handleLock = async (from, to, uId, isCompleted) => {
    from = switchDateFormat(from);
    to = switchDateFormat(to);

    if (!from || !to || !uId) return;

    // if unlocked => lock
    if (!isCompleted) {
      try {
        const response = await axios.patch(
          `/api/timesheet/lockTimesheet/${from}/${to}/${uId}`
        );
        if (response.data.success) {
          setUserTSData((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.forEach((item) => {
              if (
                item.fromDate === switchDateFormat(from) &&
                item.toDate === switchDateFormat(to) &&
                item.userId === Number(uId)
              ) {
                item.isCompleted = true;
              }
            });
            return newData;
          });
          displayMessage(SUCCESS, 'Timesheet locked.');
        } else {
          displayMessage(ERROR, 'Not success: ' + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
      }
    }
    // if locked => unlock
    else {
      try {
        const response = await axios.patch(
          `/api/timesheet/unlockTimesheet/${from}/${to}/${uId}`
        );
        if (response.data.success) {
          setUserTSData((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.forEach((item) => {
              if (
                item.fromDate === switchDateFormat(from) &&
                item.toDate === switchDateFormat(to) &&
                item.userId === Number(uId)
              ) {
                item.isCompleted = false;
              }
            });
            return newData;
          });
          displayMessage(SUCCESS, 'Timesheet is unlocked.');
        } else {
          displayMessage(ERROR, 'Not success: ' + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
      }
    }
  };

  const handleApprove = async (from, to, uId, approvedAt) => {
    from = switchDateFormat(from);
    to = switchDateFormat(to);

    if (!from || !to || !uId) return;

    // if unlocked => lock
    if (!approvedAt) {
      try {
        const response = await axios.put(
          `/api/timesheet/approveTimesheet/${from}/${to}/${uId}`,
          currentUser
        );
        if (response.data.success) {
          setUserTSData((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.forEach((item) => {
              if (
                item.fromDate === switchDateFormat(from) &&
                item.toDate === switchDateFormat(to) &&
                item.userId === Number(uId)
              ) {
                item.approvedAt = new Date();
                item.approvedBy = currentUser.userId;
              }
            });
            return newData;
          });
          displayMessage(SUCCESS, 'Timesheet approved.');
        } else {
          displayMessage(ERROR, 'Not success: ' + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
      }
    }
    // if locked => unlock
    else {
      try {
        const response = await axios.patch(
          `/api/timesheet/unapproveTimesheet/${from}/${to}/${uId}`,
          currentUser
        );
        if (response.data.success) {
          setUserTSData((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.forEach((item) => {
              if (
                item.fromDate === switchDateFormat(from) &&
                item.toDate === switchDateFormat(to) &&
                item.userId === Number(uId)
              ) {
                item.approvedAt = null;
                item.approvedBy = null;
              }
            });
            return newData;
          });
          displayMessage(SUCCESS, 'Timesheet unapproved.');
        } else {
          displayMessage(ERROR, 'Not success: ' + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
      }
    }
  };
  const handleProcess = async (from, to, uId, processedAt) => {
    from = switchDateFormat(from);
    to = switchDateFormat(to);

    if (!from || !to || !uId) return;

    // if unlocked => lock
    if (!processedAt) {
      try {
        const response = await axios.put(
          `/api/timesheet/processTimesheet/${from}/${to}/${uId}`,
          currentUser
        );
        if (response.data.success) {
          setUserTSData((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.forEach((item) => {
              if (
                item.fromDate === switchDateFormat(from) &&
                item.toDate === switchDateFormat(to) &&
                item.userId === Number(uId)
              ) {
                item.processedAt = new Date();
                item.processedBy = currentUser.userId;
              }
            });
            return newData;
          });
          displayMessage(SUCCESS, 'Timesheet processed.');
        } else {
          displayMessage(ERROR, 'Not success: ' + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
      }
    }
    // if locked => unlock
    else {
      try {
        const response = await axios.patch(
          `/api/timesheet/unprocessTimesheet/${from}/${to}/${uId}`,
          currentUser
        );
        if (response.data.success) {
          setUserTSData((prevData) => {
            const newData = JSON.parse(JSON.stringify(prevData));
            newData.forEach((item) => {
              if (
                item.fromDate === switchDateFormat(from) &&
                item.toDate === switchDateFormat(to) &&
                item.userId === Number(uId)
              ) {
                item.processedAt = null;
                item.processedBy = null;
              }
            });
            return newData;
          });
          displayMessage(SUCCESS, 'Timesheet unprocessed.');
        } else {
          displayMessage(ERROR, 'Not success: ' + response.data.error);
        }
      } catch (error) {
        displayMessage(ERROR, 'Internal error: ' + error.response.data.error);
      }
    }
  };
  const handleView = async (from, to, uId) => {
    from = switchDateFormat(from);
    to = switchDateFormat(to);
    navigate(`/${TIMESHEET}/${uId}/${from}/${to}`);
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container pc-public uts-container'>
        <div className='uts-header-name'>
          {userInfo.firstName} {userInfo.lastName}
        </div>
        <h2>Timesheet Records</h2>

        <table className='user-timesheet-record-table'>
          <thead>
            <tr>
              <th className='utrt-first-col'>Performance Period</th>
              <th>Due Date</th>
              <th>Availability</th>
              <th>Lock</th>
              <th>Approval</th>
              <th>Process</th>
              <th className='utrt-last-col'>Controller</th>
            </tr>
          </thead>
          <tbody>
            {payPeriods.map((payPeriod) => (
              <PayPeriodUserRecord
                key={payPeriod.id}
                payPeriod={payPeriod}
                userTimesheet={userTSData}
                groupId={currentUser.groupId}
                userId={userId}
                onCreate={handleCreate}
                onDelete={handleDelete}
                onLock={handleLock}
                onApprove={handleApprove}
                onProcess={handleProcess}
                onView={handleView}
              />
            ))}
          </tbody>
        </table>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')}
          />
        )}
      </div>
    </div>
  );
}

export default UserTimesheetPage;
