import React, { createContext, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchRole } from "../pages/UserRoleAccessPage/Role/roleSlice";
import { fetchAccess } from "../pages/UserRoleAccessPage/Access/accessSlice";
import { fetchRoleAccess } from "../pages/UserRoleAccessPage/RoleAccess/roleAccessSlice";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Call the /auth/status endpoint to check if the user is authenticated
    fetch("/api/user/auth/status")
      .then((response) => response.json())
      .then((data) => {
        if (data.isAuthenticated) {
          setCurrentUser(data.user);
        } else {
          setCurrentUser(null);
        }
        setLoading(false); // Auth check is done
      })
      .catch((error) => {
        console.error("Error checking auth status:", error);
        setLoading(false); // Auth check is done even if there's an error
      });
  }, []);

  const login = (user) => {
    setCurrentUser(user);
    fetchInitData();
  };

  const logout = () => {
    setCurrentUser(null);
  };

  // If it's still loading, you can return null or a loading spinner
  if (loading) {
    return null; // Or return a loading spinner
  }

  const fetchInitData = async () => {
    dispatch(fetchRole());
    dispatch(fetchAccess());
    dispatch(fetchRoleAccess());
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>{children}</AuthContext.Provider>
  );
};
