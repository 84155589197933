import '../resources/css/general.css';
import './styles.css';

import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext';
import {
  MY_ACCOUNT,
  RESET_PASSWORD,
  DIRECTORY,
} from '../../constants/routeUrl';
import { Link } from 'react-router-dom';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate(`/${DIRECTORY}`);
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/user/login', {
        email,
        password,
      });

      if (response.data.success) {
        // Successful login. Redirect or update state accordingly.
        login(response.data.user);
        navigate(`/${MY_ACCOUNT}`);
      } else {
        // Handle error messages from the server
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      // Handle any other errors
      setErrorMessage('Error logging in. Please try again.' + error);
    }
  };

  return (
    <div>
      <Header />
      <div className='login-container'>
        <form
          onSubmit={handleSubmit}
          className='login-form'
        >
          <h2>Login</h2>
          <div className='input-group'>
            <label htmlFor='email'>Email:</label>
            <input
              id='email'
              name='email'
              type='email'
              value={email}
              autoComplete='username'
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='input-group'>
            <label htmlFor='password'>Password:</label>
            <input
              id='password'
              name='password'
              type='password'
              value={password}
              autoComplete='current-password'
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            New User? Contact <a href='mailto:louis@dfdva.com'>Louis</a>
            &nbsp;or&nbsp;
            <a href='mailto:agiles@dfdva.com'>Angela</a>
          </div>
          <div>
            Forgot Password? <Link to={'/' + RESET_PASSWORD}>Click here</Link>
          </div>
          {errorMessage && <p>{errorMessage}</p>}
          <button type='submit'>Login</button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
