import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk actions
export const fetchAccess = createAsyncThunk(
  "access/fetchAccess",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/access");
      return response.data.data; // Assuming the data is in response.data.data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createAccess = createAsyncThunk(
  "access/createAccess",
  async (newItem, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/access", newItem);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAccess = createAsyncThunk(
  "access/updateAccess",
  async (updateItem, { rejectWithValue }) => {
    try {
      await axios.put(`/api/access/${updateItem.id}`, updateItem);
      return updateItem; // Assuming you want to return the updated item
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAccess = createAsyncThunk(
  "access/deleteAccess",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/access/${id}`);
      return id; // Return the id of the deleted item
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Slice with extraReducers to handle all async actions
const accessSlice = createSlice({
  name: "access",
  initialState: {
    accessList: [],
    loading: false,
    error: null,
    accessFirstLoaded: true,
  },
  reducers: {
    // Synchronous action reducers here...
  },
  extraReducers: (builder) => {
    builder
      // Handle createAccess actions
      .addCase(fetchAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.accessFirstLoaded = false;
      })
      .addCase(fetchAccess.fulfilled, (state, action) => {
        state.accessList = action.payload;
        state.loading = false;
        state.accessFirstLoaded = false;
      })
      .addCase(fetchAccess.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.accessFirstLoaded = false;
      })
      // Handle createAccess actions
      .addCase(createAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAccess.fulfilled, (state, action) => {
        state.accessList.push(action.payload);
        state.loading = false;
      })
      .addCase(createAccess.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Handle updateAccess actions
      .addCase(updateAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAccess.fulfilled, (state, action) => {
        const index = state.accessList.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.accessList[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateAccess.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Handle deleteAccess actions
      .addCase(deleteAccess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAccess.fulfilled, (state, action) => {
        state.accessList = state.accessList.filter((item) => item.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteAccess.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default accessSlice.reducer;
