import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk actions for RoleAccess
export const fetchRoleAccess = createAsyncThunk(
  "roleAccess/fetchRoleAccess",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/role/getAllRoleAccess`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createRoleAccess = createAsyncThunk(
  "roleAccess/createRoleAccess",
  async ({ RoleId, AccessId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/role/${RoleId}/access/${AccessId}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteRoleAccess = createAsyncThunk(
  "roleAccess/deleteRoleAccess",
  async ({ RoleId, AccessId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/role/${RoleId}/access/${AccessId}`);
      return { RoleId, AccessId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// RoleAccess slice
const roleAccessSlice = createSlice({
  name: "roleAccess",
  initialState: {
    roleAccessList: [],
    loading: false,
    error: null,
    roleAccessFirstLoaded: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleAccess.pending, (state) => {
        state.loading = true;
        state.roleAccessFirstLoaded = false;
      })
      .addCase(fetchRoleAccess.fulfilled, (state, action) => {
        state.roleAccessList = action.payload;
        state.loading = false;
        state.roleAccessFirstLoaded = false;
      })
      .addCase(fetchRoleAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.roleAccessFirstLoaded = false;
      })
      .addCase(createRoleAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRoleAccess.fulfilled, (state, action) => {
        state.roleAccessList.push(action.payload);
        state.loading = false;
      })
      .addCase(createRoleAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteRoleAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoleAccess.fulfilled, (state, action) => {
        const { RoleId, AccessId } = action.payload;
        state.roleAccessList = state.roleAccessList.filter(
          (ra) => !(ra.RoleId === RoleId && ra.AccessId === AccessId)
        );
        state.loading = false;
      })
      .addCase(deleteRoleAccess.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default roleAccessSlice.reducer;
