import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { ERROR } from '../../constants/messageTypes';
import { useNavigate } from 'react-router-dom';
import { ADMIN, MANAGER, SUPER_MANAGER } from '../../constants/roles';
import { BsCalendar2Week } from 'react-icons/bs';
import { useAuth } from '../../contexts/authContext';
import { USER_TIMESHEET } from '../../constants/routeUrl';

const UnderMeUserTimesheetPage = () => {
  const currentUser = useAuth().currentUser;
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');

  const loadUsers = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/user/under-me/${currentUser.userId}`
      );
      setUsers(response.data.data);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  const handleOpenUserTimesheet = (user) => {
    navigate(`/${USER_TIMESHEET}/${user.id}`);
  };

  if (users.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <div className='payPeriod-container'>
        <h2>Employees</h2>
        <div className='item-list'>
          {users.map((user) => {
            const isUserAdmin = user.groupId === ADMIN;
            const isUserSuperManager = user.groupId === SUPER_MANAGER;
            const isUserManager = user.groupId === MANAGER;

            const userClassName = [
              'us-name',
              isUserAdmin && 'up-admin up-special',
              isUserSuperManager && 'up-super-manager up-special',
              isUserManager && 'up-manager up-special',
            ]
              .filter(Boolean)
              .join(' ');

            return (
              <div
                className='g-row'
                key={user.id}
              >
                <span className='g-col-A'>
                  <div className={userClassName}>
                    {user.firstName} {user.lastName}
                  </div>
                  <div className='us-email'>{user.email}</div>
                </span>
                <div className='g-col-B'>
                  <div className='g-svg_wrapper'>
                    {[MANAGER, SUPER_MANAGER, ADMIN].includes(
                      currentUser.groupId
                    ) && (
                      <BsCalendar2Week
                        aria-label='Edit'
                        className='icon-controller'
                        onClick={() => handleOpenUserTimesheet(user)}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')} // Clear the message after it disappears
          />
        )}
      </div>
    </div>
  );
};

export default UnderMeUserTimesheetPage;
