import { BsFillCaretRightFill } from "react-icons/bs";

const MenuItem = ({ menuItem, onChangeSelection, selectedOption }) => {
  return (
    <span
      className={`menu-item ${selectedOption === menuItem.option ? "menu-item-selected" : ""}`}
      onClick={() => onChangeSelection(menuItem.option)}
    >
      <div className="menu-item-icon">
        <BsFillCaretRightFill size={18} />
      </div>
      <div className="menu-item-text">{menuItem.text}</div>
    </span>
  );
};

export default MenuItem;
