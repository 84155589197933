import React, { useState } from "react";
import { FaEdit, FaTrash, FaExpandArrowsAlt } from "react-icons/fa";
import ContactInfo from "./ContactValue";
import "./styles.css";
import { sanitizePhoneNumber } from "../../utils/StringUtils";

const ContactItem = ({ contact, currentUser, handleDelete, handleEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="contact-item">
      <div className="teamContainer">
        <span className="team-label">TEAM:</span> {contact.team.teamName}
      </div>
      <div className="row top-row">
        <div className="contactName">
          {contact.firstName} {contact.lastName}
        </div>

        {currentUser && (
          <div className="contact-item-controller">
            <FaExpandArrowsAlt
              onClick={() => setIsExpanded(!isExpanded)}
              className="icon-controller"
            />
            <FaEdit onClick={() => handleEdit(contact.id)} className="icon-controller" />
            <FaTrash onClick={() => handleDelete(contact.id)} className="icon-controller" />
          </div>
        )}
      </div>

      <div className="row row-content">
        <ContactInfo label="Work Phone" value={sanitizePhoneNumber(contact.workPhone)} />
        <ContactInfo label="Work Email" value={contact.workEmail} />
        <ContactInfo label="Gov Email" value={contact.govEmail} />
        <ContactInfo label="Team" value={contact.team.teamName} />
      </div>

      <div className="row">
        <ContactInfo label="Personal Phone" value={sanitizePhoneNumber(contact.personalPhone)} />
        <ContactInfo label="Personal Email" value={contact.personalEmail} />
        <ContactInfo label="Gov Phone" value={sanitizePhoneNumber(contact.govPhone)} />
        <ContactInfo label="Title" value={contact.title} />
      </div>

      {isExpanded && (
        <div>
          <div className="row">
            <ContactInfo label="Egy Contact Name" value={contact.egyCtEmail} />
            <ContactInfo
              label="Egy Contact Phone"
              value={sanitizePhoneNumber(contact.egyCtPhone)}
            />
            <ContactInfo label="Egy Contact Relationship" value={contact.egyCtRelationship} />
            <ContactInfo label="Egy Contact Email" value={contact.egyCtEmail} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactItem;
