import '../resources/css/general.css';
import './styles.css';

import Header from '../../components/Header';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { ERROR } from '../../constants/messageTypes';
import { ADMIN, MANAGER, SUPER_MANAGER } from '../../constants/roles';

const HierachicalOrganizationPage = () => {
  const [users, setUsers] = useState([]);
  const [messageType, setMessageType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // Helper function to create a tree structure
  const createTree = (users, supervisorId = null, level = 0) => {
    return users
      .filter((user) => user.supervisorId === supervisorId)
      .map((user) => ({
        ...user,
        directBossIndent: supervisorId ? 1 : 0, // 1 if the user has a direct boss, otherwise 0
        indirectBossIndent: level, // Level of indentation based on hierarchy
        subordinates: createTree(users, user.id, level + 1),
      }));
  };

  // Flatten the tree structure while preserving the indentation
  const flattenTree = (tree) => {
    return tree.reduce((acc, node) => {
      acc.push({
        ...node,
        subordinates: undefined,
      });
      if (node.subordinates.length) {
        // Increase the indirectBossIndent for subordinates
        const subordinatesWithIncreasedIndent = node.subordinates.map(
          (subordinate) => ({
            ...subordinate,
            indirectBossIndent:
              subordinate.indirectBossIndent + node.directBossIndent,
          })
        );
        acc = acc.concat(flattenTree(subordinatesWithIncreasedIndent));
      }
      return acc;
    }, []);
  };

  const loadUsers = useCallback(async () => {
    try {
      const response = await axios.get('/api/user');
      const userTree = createTree(response.data);
      const sortedAndIndentedUsers = flattenTree(userTree);
      setUsers(sortedAndIndentedUsers);
    } catch (error) {
      displayMessage(ERROR, 'Error:', error);
    }
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const displayMessage = (type, text) => {
    setMessageType(type);
    setMessageText(text);
  };

  return (
    <div>
      <Header />
      <div className='payPeriod-container us-list-container'>
        <h2>Hierarchical Organization</h2>
        <div className='item-list hierarchy-list'>
          {users.map((user) => (
            <div
              className='g-row'
              key={user.id}
              style={{ paddingLeft: `${user.indirectBossIndent * 30}px` }} // Indent based on indirect bosses
            >
              <span className='g-col-A'>
                <div>
                  {user.directBossIndent ? '|------- ' : ''}{' '}
                  {/* Dash only if direct boss */}
                  {user.firstName} {user.lastName}
                </div>
              </span>
              <div className='g-col-B'>
                <div className='g-svg_wrapper'></div>
              </div>
            </div>
          ))}
        </div>
        {messageText && (
          <MessageBox
            type={messageType}
            message={messageText}
            onClear={() => setMessageText('')} // Clear the message after it disappears
          />
        )}
      </div>
    </div>
  );
};

export default HierachicalOrganizationPage;
