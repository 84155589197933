import { combineReducers } from "@reduxjs/toolkit";
import accessReducer from "../pages/UserRoleAccessPage/Access/accessSlice";
import roleReducer from "../pages/UserRoleAccessPage/Role/roleSlice";
import roleAccessReducer from "../pages/UserRoleAccessPage/RoleAccess/roleAccessSlice";

const rootReducer = combineReducers({
  access: accessReducer,
  role: roleReducer,
  roleAccess: roleAccessReducer,
});

export default rootReducer;
