import "../resources/css/general.css";
import "./styles.css";

import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../contexts/authContext";
import { MY_ACCOUNT } from "../../constants/routeUrl";
import { useSearchParams } from "react-router-dom";

function ResetPasswordDonePage() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser || !code) {
      navigate(`/${MY_ACCOUNT}`);
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    const checkCodeAndGetNewPassword = async () => {
      try {
        const response = await axios.post(`/api/user/gen-new-password`, {
          code,
        });
        if (response.data.success) {
          setNewPassword(response.data.newPassword);
        }
      } catch (error) {
        setErrorMessage(error.response.data.error);
      }
    };

    checkCodeAndGetNewPassword();
  }, []);

  return (
    <div>
      <Header />
      <div className="login-container">
        <form className="login-form">
          <h2>Reset Step 2</h2>
          <div className="input-group">
            <label htmlFor="resetCode">Reset Code:</label>
            <input id="resetCode" name="resetCode" type="text" value={code} readOnly />
          </div>
          <div className="input-group">
            <label htmlFor="password">New Password:</label>
            <input
              id="password"
              name="password"
              type="text"
              value={newPassword}
              autoComplete="current-password"
              readOnly
            />
          </div>
          {errorMessage && <p>{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordDonePage;
