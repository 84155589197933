import React, { useState, useEffect, useMemo } from "react";
import { subtractTimes } from "../../utils/DateUtils";
import "./styles.css";
import { convert12To24 } from "../../utils/DateUtils";

const BreakEditModal = ({ visible, breakData, columnIndex, onSave, onCancel }) => {
  const [fromHour, setFromHour] = useState("01");
  const [fromMinute, setFromMinute] = useState("00");
  const [fromAMPM, setFromAMPM] = useState("AM");
  const [toHour, setToHour] = useState("01");
  const [toMinute, setToMinute] = useState("00");
  const [toAMPM, setToAMPM] = useState("AM");
  const [validationError, setValidationError] = useState("");
  const [breaks, setBreaks] = useState([]);

  useEffect(() => {
    if (columnIndex) setBreaks(breakData[columnIndex]);
  }, [breakData, columnIndex]);

  const handleCancel = () => {
    setFromHour("01");
    setFromMinute("00");
    setFromAMPM("AM");
    setToHour("01");
    setToMinute("00");
    setToAMPM("AM");
    setBreaks([]);
    setValidationError("");
    onCancel();
  };

  const getTotalBreakTime = () => {
    const totalBreakTime = breaks.reduce((acc, interval) => {
      const [from, to] = interval.split("-");
      const diff = subtractTimes(from, to);
      return acc + diff;
    }, 0);
    return totalBreakTime;
  };

  const totalBreakTime = useMemo(() => {
    return getTotalBreakTime();
  }, [breaks]);

  const isOverlapping = (newPeriod, periods) => {
    const [newStart, newEnd] = newPeriod
      .split("-")
      .map((time) => parseInt(time.replace(":", ""), 10));

    return periods.some((period) => {
      const [start, end] = period.split("-").map((time) => parseInt(time.replace(":", ""), 10));
      return newStart < end && newEnd > start;
    });
  };

  const isValidInterval = (from, to) => {
    const [fromTime, fromPeriod] = from.split(" ");
    const [fromHour, fromMinute] = fromTime.split(":");
    const from24Hr = `${convert12To24(fromHour, fromPeriod)}:${fromMinute}`;

    const [toTime, toPeriod] = to.split(" ");
    const [toHour, toMinute] = toTime.split(":");
    const to24Hr = `${convert12To24(toHour, toPeriod)}:${toMinute}`;

    const diff = subtractTimes(from24Hr, to24Hr);
    if (diff <= 0) {
      setValidationError("Invalid time interval. Try again!");
      return false;
    } else {
      setValidationError("");
      return true;
    }
  };

  const handleSave = () => {
    const newBreaks = breakData.map((b, index) => {
      return index === columnIndex ? breaks : b;
    });
    onSave(newBreaks);
    handleCancel();
  };

  const handleAdd = () => {
    const fromTime = `${convert12To24(fromHour, fromAMPM)}:${fromMinute}`;
    const toTime = `${convert12To24(toHour, toAMPM)}:${toMinute}`;
    if (
      !isValidInterval(`${fromHour}:${fromMinute} ${fromAMPM}`, `${toHour}:${toMinute} ${toAMPM}`)
    ) {
      return;
    }
    const newInterval = `${fromTime}-${toTime}`;
    if (breaks.includes(newInterval)) {
      setValidationError("Break interval already exists. Try again!");
      return;
    }
    if (isOverlapping(newInterval, breaks)) {
      setValidationError("Break interval overlaps with existing interval. Try again!");
      return;
    }
    setBreaks([...breaks, newInterval]);
  };

  const handleDelete = (index) => {
    const newBreaks = breaks.filter((_, i) => i !== index);
    setBreaks(newBreaks);
  };

  if (!visible) return null;

  const quickReset = () => {
    setBreaks([]);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="float-left">Modifying Break Time in Day</h2>
        <button className="btnReset" onClick={quickReset}>
          Clear
        </button>
        <div className="clr"></div>
        <hr></hr>
        <table>
          <tbody>
            <tr>
              <td>
                <label>From:</label>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={fromHour}
                  onChange={(e) => setFromHour(e.target.value)}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
                    <option value={num.toString().padStart(2, "0")} key={num}>
                      {num.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={fromMinute}
                  onChange={(e) => setFromMinute(e.target.value)}
                >
                  {["00", "15", "30", "45"].map((num) => (
                    <option value={num} key={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={fromAMPM}
                  onChange={(e) => setFromAMPM(e.target.value)}
                >
                  {["AM", "PM"].map((period) => (
                    <option value={period} key={period}>
                      {period}
                    </option>
                  ))}
                </select>
              </td>
              <td className="ts-divider">
                <label>To:</label>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={toHour}
                  onChange={(e) => setToHour(e.target.value)}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((num) => (
                    <option value={num.toString().padStart(2, "0")} key={num}>
                      {num.toString().padStart(2, "0")}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={toMinute}
                  onChange={(e) => setToMinute(e.target.value)}
                >
                  {["00", "15", "30", "45"].map((num) => (
                    <option value={num} key={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  className="dropdown-time"
                  value={toAMPM}
                  onChange={(e) => setToAMPM(e.target.value)}
                >
                  {["AM", "PM"].map((period) => (
                    <option value={period} key={period}>
                      {period}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td colSpan="8">
                {validationError && <div className="time-modal-error-msg">{validationError}</div>}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="time-modal-btn-container">
          <button className="time-modal-btn time-modal-btn-left" onClick={handleAdd}>
            Add
          </button>
          <button className="time-modal-btn time-modal-btn-left" onClick={handleSave}>
            Save
          </button>
          <button className="time-modal-btn time-modal-btn-left" onClick={handleCancel}>
            Close
          </button>
        </div>
        <div className="breakTimeContainerWrapper">
          <div className="breakTimeContainer">
            {breaks.map((interval, index) => {
              const [from, to] = interval.split("-");
              const [fromTime, fromPeriod] = from.split(" ");
              const [fromHour, fromMinute] = fromTime.split(":");
              const [toTime, toPeriod] = to.split(" ");
              const [toHour, toMinute] = toTime.split(":");
              return (
                <div className="breakTimeRow" key={index}>
                  <div className="breakTimeData">
                    Period: {fromHour}:{fromMinute} - {toHour}:{toMinute}
                  </div>
                  <div className="breakTimeDuration">Duration: {subtractTimes(from, to)} hrs</div>
                  <div className="breakTimeDelete" onClick={() => handleDelete(index)}>
                    <span className="breakTimeDeleteBtn">X</span>
                    &nbsp;Delete
                  </div>
                </div>
              );
            })}
          </div>
          <div className="totalBreakTime">
            <h4>Total Self-break:</h4> <div className="tbt-amount">{totalBreakTime} hrs</div>
          </div>
          <div className="clr"></div>
        </div>
      </div>
    </div>
  );
};

export default BreakEditModal;
