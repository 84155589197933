import './styles.css';

const MessageModal = ({ visible, message, onCancel }) => {
  if (!visible) return null;

  return (
    <div className='modal-overlay'>
      <div className='modal-content message-modal'>
        <h3>Message Box</h3>
        <div className='input-group'>
          <div className='message-box-content'>{message}</div>
          <button onClick={onCancel}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
