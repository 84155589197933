import React, { useEffect } from 'react';
import '../resources/css/general.css';
import './styles.css';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from '../../constants/routeUrl';

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${LOGIN}`);
  }, [navigate]);

  return (
    <div>
      <Header />
      <div>... Redirect ...</div>
    </div>
  );
}

export default HomePage;
