import { IoAddCircleOutline } from "react-icons/io5";
import { useState } from "react";

const AccessModal = ({ visible, onClose, role, accessList, onAdd }) => {
  const [filteredAccessList, setFilteredAccessList] = useState(accessList);

  const handleKeywordChange = (e) => {
    const keyword = e.target.value;
    setFilteredAccessList(
      accessList.filter((item) => item.AccessName.toLowerCase().includes(keyword.toLowerCase()))
    );
  };

  if (!visible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="mapping-container">
          <h2>Access List</h2>
          <input
            type="text"
            name="txtKeyword"
            id="txtKeyword"
            placeholder="Access Search ..."
            onChange={handleKeywordChange}
          />
          <div className="item-list-wrapper">
            <div className="item-list">
              {filteredAccessList.map((item) => (
                <div className="g-row" key={item.id}>
                  <div className="g-col-A">{item.AccessName}</div>
                  <div className="g-col-B">
                    <div className="g-svg-wrapper">
                      <IoAddCircleOutline
                        className="icon-controller"
                        onClick={() => onAdd(role.id, item.id)}
                      >
                        Add
                      </IoAddCircleOutline>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button className="btn-120" onClick={() => onClose()}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessModal;
