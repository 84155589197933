import '../resources/css/general.css';
import './styles.css';

import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/authContext';
import { MY_ACCOUNT } from '../../constants/routeUrl';

function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/contact-list');
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const msg =
      'You should receive a secret code to reset your password. Otherwise, contact Admin.';
    try {
      const response = await axios.post('/api/user/self-reset-password', {
        email,
      });

      if (response.data.success) {
        setErrorMessage(msg);
      } else {
        console.log(response.data.error);
        setErrorMessage(msg);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(msg);
    }
  };

  return (
    <div>
      <Header />
      <div className='login-container'>
        <form
          onSubmit={handleSubmit}
          className='login-form'
        >
          <h2>Reset Password</h2>
          <div className='input-group'>
            <label htmlFor='email'>Email:</label>
            <input
              id='email'
              name='email'
              type='email'
              value={email}
              autoComplete='current-password'
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {errorMessage && <p>{errorMessage}</p>}
          <button type='submit'>Send Request</button>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
