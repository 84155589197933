import { useState } from "react";
import MenuItem from "./MenuItem";
import "./styles.css";

const VerticalMenu = ({ menuItems, selectedOption, onChangeSelection }) => {
  return (
    <table className="v-menu">
      <tbody>
        {menuItems.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                <MenuItem
                  menuItem={item}
                  selectedOption={selectedOption}
                  onChangeSelection={onChangeSelection}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default VerticalMenu;
